import { type EventModelConfig } from '@bryntum/calendar';

import { type EquationField } from '@/types/schema/fields';
import { type KnackField, type KnackFieldKey } from '@/types/schema/fields/KnackField';
import { isCriteriaMet } from '@/utils/criteriaRules';
import { type RepeatOptionType, type TransformDataType } from './type';

export const mappedMode = {
  agendaWeek: 'week',
  month: 'month',
  agendaDay: 'day'
};

export const mappedWeekStartDay = {
  sunday: 0,
  monday: 1
};

function transformRecurrenceRule(rule: RepeatOptionType) {
  const { end_date: endDate, interval, frequency, endson } = rule;

  let recurrenceRule = '';

  // Define the frequency (DAILY, WEEKLY, etc.)
  const freqMapping = {
    daily: 'DAILY',
    weekly: 'WEEKLY',
    monthly: 'MONTHLY',
    yearly: 'YEARLY'
  };

  recurrenceRule += `FREQ=${freqMapping[frequency]};`;

  // Define the interval
  recurrenceRule += `INTERVAL=${interval};`;

  // Define UNTIL for endson === "limit" or "date"
  if ((endson === 'limit' || endson === 'date') && endDate) {
    // Convert end_date to the correct format (YYYYMMDDTHHmmssZ)
    const untilDate = new Date(endDate);
    const until = `${untilDate.toISOString().replace(/[-:]/g, '').slice(0, 15)}Z`; // Format: YYYYMMDDTHHmmssZ
    recurrenceRule += `UNTIL=${until}`;
  }

  // If endson is "never", the rule should not have an UNTIL field.
  if (endson === 'never') {
    recurrenceRule += `UNTIL=;`; // This is optional, you can omit it
  }

  // Return the transformed event data
  return recurrenceRule;
}

const isDefined = <T>(value: T | undefined): value is T => value !== undefined;

export const getBryntumEvents = ({
  tableData = [],
  eventField = '',
  labelField = '',
  sourceTable,
  event_color_default = '#3366cc',
  event_colors = []
}: TransformDataType): EventModelConfig[] => {
  const ids: Array<string> = [];

  const events = tableData.map((record) => {
    // add only unique events
    if (ids.includes(record.rawValues.id || '')) {
      return undefined;
    }

    ids.push(record.rawValues.id || '');

    let repeat;
    const eventColor = event_colors.find((event_color) => {
      const criteriaField = sourceTable.fields.find((field) => field.key === event_color.field);
      if (!criteriaField) {
        return false;
      }

      return isCriteriaMet(record.rawValues[event_color.field], criteriaField, event_color);
    })?.color;

    if (record.rawValues[eventField].repeat) {
      repeat = transformRecurrenceRule(record.rawValues[eventField].repeat);
    }
    return {
      ...record.rawValues,
      startDate: record.rawValues[eventField].iso_timestamp.replace(/Z$/, ''),
      name: record.rawValues[labelField],
      allDay: record.rawValues[eventField].all_day || !record.rawValues[eventField].to,
      endDate:
        record.rawValues[eventField].to?.iso_timestamp.replace(/Z$/, '') ||
        record.rawValues[eventField].iso_timestamp.replace(/Z$/, ''),
      eventColor: eventColor || event_color_default,
      recurrenceRule: repeat
    };
  });
  // filter undefined -- obtained because of duplicate events sent from API
  return events.filter(isDefined);
};

export const getFilters = ({
  startDate,
  endDate,
  key
}: {
  startDate: Date;
  endDate: Date;
  key: KnackFieldKey;
}): string =>
  JSON.stringify({
    match: 'and',
    rules: [
      {
        field: key,
        operator: 'is after',
        value: startDate.toISOString()
      },
      {
        field: key,
        operator: 'is before',
        value: endDate.toISOString()
      }
    ]
  });

const isEquationFieldWithDateFormat = (
  field: KnackField
): field is EquationField & {
  format: { equation_type: 'date'; date_result: 'date' };
} =>
  field.type === 'equation' &&
  field.format?.equation_type === 'date' &&
  field.format?.date_result === 'date';

export const canFieldStoreDateValues = (field: KnackField) =>
  field.type === 'date_time' || isEquationFieldWithDateFormat(field);

// Define a type for a nested collection (objects or arrays)
type Collection<T> = T | { [key: string]: Collection<T> } | Collection<T>[];

/**
 * Recursively checks if any object in the collection contains a specific key-value pair.
 * @param collection - The object or array to search through.
 * @param key - The key to search for.
 * @param value - The value to check for.
 * @returns `true` if any object contains the specified key-value pair, `false` otherwise.
 */
export function someDeep<T>(
  collection: Collection<T>,
  key: string,
  value: string | number | boolean
): boolean {
  // If collection is an array, iterate over the elements
  if (Array.isArray(collection)) {
    return collection.some((item) => someDeep(item, key, value));
  }

  // If collection is an object, iterate over the object values
  if (typeof collection === 'object' && collection !== null) {
    const objCollection = collection as { [key: string]: Collection<T> };

    // Check if the object contains the specified key-value pair
    if (key in objCollection && objCollection[key] === value) {
      return true;
    }

    // Recurse through the object values
    return Object.values(objCollection).some((valueItem) => someDeep(valueItem, key, value));
  }

  // Return false for non-object/array values
  return false;
}
