import { type TdHTMLAttributes } from 'react';
import { Rating, Table } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/fields/KnackField';

interface ListItemValueCellProps extends TdHTMLAttributes<HTMLTableCellElement> {
  field: KnackField | undefined;
  value: string;
}

export function ListItemValueCell(props: ListItemValueCellProps) {
  const { field, value, dangerouslySetInnerHTML, ...rest } = props;

  if (field?.type === 'rating') {
    return (
      <Table.Cell {...rest}>
        <Rating
          ratingNumber={Number(value)}
          maxRatingNumber={parseInt(field?.format.max, 10)}
          allowHalf={field?.format.allow_half}
        />
      </Table.Cell>
    );
  }

  return <Table.Cell {...rest} dangerouslySetInnerHTML={dangerouslySetInnerHTML} />;
}
