import { type CSSProperties } from 'react';
import { Spinner } from '@knack/asterisk-react';

import { type ChartData, type ReportViewChart } from '@/types/schema/views/ReportView';
import { AreaChartRender } from './AreaChartRender';
import { BarChartHorizontalRender } from './BarChartHorizontalRender';
import { BarChartRender } from './BarChartRender';
import { LineChartRender } from './LineChartRender';
import { PieChartRender } from './PieChartRender';
import { PivotTableRender } from './PivotTableRender';

interface ChartRenderProps {
  chart: ReportViewChart;
  chartData: ChartData | undefined;
  className?: string;
}

export function ChartRender({ chart, chartData, className }: ChartRenderProps) {
  if (!chartData) {
    return (
      <div className="flex size-80 items-center justify-center">
        <Spinner />
      </div>
    );
  }

  const style: CSSProperties =
    chart.layout?.dimensions === 'custom'
      ? {
          maxWidth: `${chart.layout.chart_width}px` || 'auto',
          maxHeight: `${chart.layout.chart_height}px` || 'auto'
        }
      : {};

  const commonProps = {
    style,
    className,
    chartData,
    chart
  };

  switch (chart.type) {
    case 'bar': {
      if (chart.layout?.bar_type === 'column') {
        return <BarChartRender {...commonProps} />;
      }
      return <BarChartHorizontalRender {...commonProps} />;
    }
    case 'pie': {
      return <PieChartRender {...commonProps} />;
    }
    case 'line': {
      return <LineChartRender {...commonProps} />;
    }
    case 'area': {
      return <AreaChartRender {...commonProps} />;
    }
    case 'table': {
      return <PivotTableRender />;
    }
    default: {
      return null;
    }
  }
}
