import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiTrash as DeleteIcon, HiPencil as EditIcon } from 'react-icons/hi';
import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { autoUpdate, FloatingPortal, useFloating } from '@floating-ui/react';
import { Table } from '@knack/asterisk-react';

import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { cn } from '@/utils/tailwind';
import { getColumnWidth } from '@/components/views/table/helper';
import { TableViewHeadContent } from '@/components/views/table/TableViewHead';
import { useViewContext } from '@/components/views/ViewContext';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import {
  useSectionEditorContext,
  type ViewInputHoverIdentifier
} from '@/pages/page/page-editor/SectionEditorContext';
import { type InsertPositionState } from './SortableTableWrapper';

export function TableHeadEditor({
  column,
  isDragOverlay = false,
  insertPositionState
}: {
  column: TableViewColumn;
  isDragOverlay?: boolean;
  insertPositionState?: InsertPositionState | null;
}) {
  const [t] = useTranslation();
  const { view } = useViewContext<TableView>();
  const { startEditViewInput, updatePage, isDraggingActive } = usePageEditorContext();

  const {
    node: sortableTableHeadRef,
    attributes,
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    transform,
    isSorting,
    transition
  } = useSortable({
    id: column.id
  });

  const style = {
    transition,
    transform: isSorting ? undefined : CSS.Translate.toString(transform)
  };

  const { setViewInputHover, currentHoveredViewInput } = useSectionEditorContext();

  const viewHeadHoverIdentifier: ViewInputHoverIdentifier = `${view.key}:${column.id}`;
  const isHeadHovered = currentHoveredViewInput === viewHeadHoverIdentifier;
  const isHeadActiveState = (isHeadHovered && !isDragging) || isDragOverlay;

  const { refs, floatingStyles } = useFloating({
    placement: 'top-start',
    elements: {
      reference: sortableTableHeadRef.current
    },
    // This is necessary to be able to update the floating element during the drag and drop animation
    whileElementsMounted(referenceEl, floatingEl, update) {
      const cleanup = autoUpdate(referenceEl, floatingEl, update, {
        animationFrame: true
      });
      return cleanup;
    }
  });

  const onInputClick = (event: React.MouseEvent) => {
    // We want to ensure that the click event originated from within the input and not from some other element that propagated the event back to the input.
    // For instance, when rendering a child form view in a modal, clicking on the modal backdrop should not trigger the click event on this connection input.
    if (event.target !== event.currentTarget) {
      return;
    }

    event.stopPropagation();
    startEditViewInput({ viewKey: view.key, viewInputId: column.id });
  };

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.key === 'Enter' || event.key === 'Space') {
      startEditViewInput({ viewKey: view.key, viewInputId: column.id });
    }
  };

  const onRemoveColumn = () => {
    const newUpdatedColumns = view.columns.filter((col) => col.id !== column.id);
    // Update the page with the updated groups
    updatePage({
      type: 'view',
      origin: 'live-app',
      action: 'update',
      updatedView: {
        ...view,
        columns: newUpdatedColumns
      }
    });
  };

  // Reset input hover state when component unmounts
  useEffect(
    () => () => {
      setViewInputHover(null);
    },
    [setViewInputHover]
  );

  return (
    <>
      <Table.Head
        {...attributes}
        ref={setNodeRef}
        role="button"
        tabIndex={0}
        onClick={onInputClick}
        onKeyDown={onInputKeyDown}
        {...(!isDraggingActive && {
          onMouseEnter: () => setViewInputHover(viewHeadHoverIdentifier),
          onMouseLeave: () => setViewInputHover(null)
        })}
        style={{ ...style, ...getColumnWidth(column) }}
        className={cn(
          'relative h-auto whitespace-nowrap border border-x-0 border-t-0 border-default px-2 py-1 text-default',
          {
            'outline outline-1 -outline-offset-1 outline-blue-500 after:absolute after:inset-0 after:bg-blue-500/10 first:rounded-tl-lg last:rounded-tr-lg':
              isHeadActiveState || isDragging,
            'bg-blue-500 bg-opacity-20 text-blue-400': isDragging,
            'border-x border-b-0 border-blue-500': isDragOverlay,
            '!border-r-4 border-r-blue-500':
              insertPositionState?.overId === column.id &&
              insertPositionState?.position === 'after',
            '!border-l-4 border-l-blue-500':
              insertPositionState?.overId === column.id &&
              insertPositionState?.position === 'before'
          }
        )}
      >
        <TableViewHeadContent column={column} />
      </Table.Head>
      {isHeadActiveState && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles }}
            className="h-auto rounded-tl-lg rounded-tr-lg border border-blue-500 bg-blue-500 p-0"
            onMouseEnter={() => setViewInputHover(viewHeadHoverIdentifier)}
            onMouseLeave={() => setViewInputHover(null)}
          >
            <div className="flex h-6 items-center px-1 text-white">
              <button
                aria-label={t('actions.drag')}
                type="button"
                className="mr-1 inline-flex size-5 cursor-move items-center justify-center rounded-md text-white hover:bg-white/25"
                ref={setActivatorNodeRef}
                {...listeners}
              >
                <DragIcon size={16} />
              </button>
              <button
                type="button"
                aria-label={t('actions.edit')}
                className="mr-1 inline-flex size-5 items-center justify-center rounded-md text-white hover:bg-white/25"
                onClick={() => startEditViewInput({ viewKey: view.key, viewInputId: column.id })}
              >
                <EditIcon size={14} />
              </button>
              <button
                type="button"
                aria-label={t('actions.delete')}
                className="inline-flex size-5 items-center justify-center rounded-md text-white hover:bg-white/25"
                onClick={onRemoveColumn}
              >
                <DeleteIcon size={14} />
              </button>
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
}
