import { useRef, useState } from 'react';
import { Controller, useFormContext, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiCalendar as CalendarIcon } from 'react-icons/hi2';
import { Checkbox, DatePicker, Input, Label } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import { type FormViewDateTimeInput } from '@/types/schema/views/form/DateTime';
import { DAY_PICKER_FROM_YEAR, DAY_PICKER_TO_YEAR } from '@/utils/constants';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { DateTimeRepeatOptions } from '@/components/views/form/inputs/date-time/DateTimeRepeatOptions';
import { getRawTime } from '@/components/views/form/inputs/timer/helper';
import { TimeInput } from '@/components/views/form/TimeInput';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';
import { defaultDateFormatMap, type DateTimePayload } from './types';
import {
  getFormattedDatePickerDate,
  getRepeatDefaultOptions,
  getTimeValue,
  isManualDateInputValid
} from './utils';

export function DateTimeInput({ input }: { input: FormViewDateTimeInput }) {
  const [t] = useTranslation();
  const { theme } = useThemingContext();
  const { view } = useViewContext();
  const {
    formState: { errors },
    getValues
  } = useFormContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);

  const inputFormat = input.format;
  const defaultDateFormat = inputFormat.date_format;
  const defaultAmPmValue = 'AM';
  const dateFormat = defaultDateFormatMap[defaultDateFormat];
  const isRangeMode = inputFormat.calendar || false;
  const isReadOnly = input.read_only;

  const inputValue = getValues(input.field.key);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isRepeatOptionsOpen, setIsRepeatOptionsOpen] = useState(inputValue.repeat !== undefined);
  const [isAllDayChecked, setIsAllDayChecked] = useState(inputValue.all_day || false);

  const shouldShowTimeInput =
    // If we have range mode with all day checked and then we switch to single date mode in the builder, it could bring all_day to true
    inputFormat.time_format !== 'Ignore Time' &&
    ((isAllDayChecked && !isRangeMode) || !isAllDayChecked);

  const getFormattedDate = (date: Date) => DateTime.fromJSDate(date).toFormat(dateFormat);

  const handleSelectDate = (
    date: Date | undefined,
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    if (!date) {
      return;
    }
    onChange({
      ...fieldValue,
      date: getFormattedDate(date)
    });
  };

  const handleSelectEndDate = (
    date: Date | undefined,
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    if (!date) {
      return;
    }
    onChange({
      ...fieldValue,
      to: {
        ...fieldValue.to,
        date: getFormattedDate(date)
      }
    });
  };

  const handleCheckboxAllDayChange = (
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    onChange({
      ...fieldValue,
      all_day: !fieldValue.all_day
    });
    setIsAllDayChecked(!isAllDayChecked);
  };

  const handleCheckRepeatOptions = (
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    setIsRepeatOptionsOpen(!isRepeatOptionsOpen);

    if (isRepeatOptionsOpen) {
      // Reset repeat options when unchecked
      onChange({
        ...fieldValue,
        repeat: undefined
      });
      return;
    }

    onChange({
      ...fieldValue,
      repeat: getRepeatDefaultOptions()
    });
  };

  return (
    <>
      <div
        className={cn('flex h-auto flex-wrap items-center gap-1 border border-default', {
          'p-1.5': isRangeMode,
          'border-destructive': errors[input.field.key],
          'rounded-lg': theme.appearance.corners === 'rounded'
        })}
      >
        {inputFormat.date_format !== 'Ignore Date' && (
          <Controller
            name={input.field.key}
            render={({ field }) => {
              const formattedDate = getFormattedDatePickerDate(field.value?.date, dateFormat);
              return (
                <DatePicker
                  selected={formattedDate}
                  onSelect={(val) => handleSelectDate(val, field.value, field.onChange)}
                  mode="single"
                  triggerRef={inputRef}
                  calendarProps={{
                    fromYear: DAY_PICKER_FROM_YEAR,
                    toYear: DAY_PICKER_TO_YEAR
                  }}
                  onDatePickerOpenChange={setIsDatePickerOpen}
                >
                  <Input.Container>
                    {!isRangeMode && (
                      <CalendarIcon
                        size={16}
                        className="absolute top-0 ml-2 flex h-full flex-col justify-center"
                      />
                    )}
                    <Input
                      disabled={isReadOnly}
                      id={`${view.key}-${input.id}-date-input`}
                      ref={inputRef}
                      value={field.value?.date}
                      {...(!isRangeMode && {
                        intent: errors[input.field.key] ? 'destructive' : 'default'
                      })}
                      className={cn('h-auto max-w-[120px] border-0 py-2 pl-7 focus:outline-0', {
                        'w-auto min-w-16 max-w-[90px] bg-muted p-1 text-center': isRangeMode
                      })}
                      onChange={(e) => {
                        const { value: manualDateInputValue } = e.target;

                        if (!isManualDateInputValid(manualDateInputValue)) {
                          return;
                        }

                        field.onChange({
                          ...field.value,
                          date: manualDateInputValue
                        });
                      }}
                      onClick={(e) => {
                        if (isDatePickerOpen) {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </Input.Container>
                </DatePicker>
              );
            }}
          />
        )}
        {shouldShowTimeInput && (
          <Controller
            name={input.field.key}
            render={({ field }) => {
              const timeValue =
                field.value.rawTime || getRawTime(field.value, inputFormat.time_format);

              return (
                <TimeInput
                  inputProps={{
                    disabled: isReadOnly
                  }}
                  format={inputFormat.time_format}
                  value={timeValue}
                  onValueChange={(time) =>
                    field.onChange({
                      ...field.value,
                      ...getTimeValue(time)
                    })
                  }
                >
                  {inputFormat.time_format === 'HH:MM am' && (
                    <TimeInput.TimeModeSelect
                      value={field.value?.am_pm}
                      onValueChange={(val) =>
                        field.onChange({
                          ...field.value,
                          am_pm: val
                        })
                      }
                    />
                  )}
                </TimeInput>
              );
            }}
          />
        )}
        {isRangeMode && (
          <div className="flex items-center gap-1">
            <span>{t('keywords.to')}</span>
            <Controller
              name={input.field.key}
              render={({ field }) => {
                const formattedDate = getFormattedDatePickerDate(field.value?.date, dateFormat);
                const formattedEndDate = getFormattedDatePickerDate(
                  field.value?.to?.date,
                  dateFormat
                );
                const timeValue =
                  field.value.to?.rawTime || getRawTime(field.value.to, inputFormat.time_format);

                return (
                  <>
                    <DatePicker
                      selected={formattedEndDate}
                      onSelect={(val) => handleSelectEndDate(val, field.value, field.onChange)}
                      mode="single"
                      triggerRef={endInputRef}
                      onDatePickerOpenChange={setIsDatePickerOpen}
                      calendarProps={{
                        fromYear: DateTime.fromJSDate(formattedDate).year,
                        fromMonth: formattedDate,
                        disabled: isReadOnly,
                        toYear: DAY_PICKER_TO_YEAR
                      }}
                    >
                      <Input.Container>
                        <Input
                          disabled={isReadOnly}
                          id={`${view.key}-${input.id}-end-date-input`}
                          ref={endInputRef}
                          className={cn(
                            'size-auto min-w-16 max-w-[90px] border-0 bg-muted p-1 text-center',
                            {
                              rounded: theme.appearance.corners === 'rounded'
                            }
                          )}
                          value={field.value?.to?.date}
                          onChange={(e) => {
                            const { value: manualDateInputValue } = e.target;

                            if (!isManualDateInputValid(manualDateInputValue)) {
                              return;
                            }

                            field.onChange({
                              ...field.value,
                              to: {
                                ...field.value.to,
                                date: manualDateInputValue
                              }
                            });
                          }}
                          onClick={(e) => {
                            if (isDatePickerOpen) {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </Input.Container>
                    </DatePicker>
                    {shouldShowTimeInput && field.value?.to && (
                      <TimeInput
                        inputProps={{
                          disabled: isReadOnly
                        }}
                        format={inputFormat.time_format}
                        value={timeValue}
                        onValueChange={(time) =>
                          field.onChange({
                            ...field.value,
                            to: { ...field.value.to, ...getTimeValue(time) }
                          })
                        }
                      >
                        {inputFormat.time_format === 'HH:MM am' && (
                          <TimeInput.TimeModeSelect
                            value={field.value?.to.am_pm || defaultAmPmValue}
                            onValueChange={(val) =>
                              field.onChange({
                                ...field.value,
                                to: { ...field.value.to, am_pm: val }
                              })
                            }
                          />
                        )}
                      </TimeInput>
                    )}
                  </>
                );
              }}
            />
          </div>
        )}
      </div>
      <FormErrorMessage errors={errors} name={input.field.key} />
      {isRangeMode && !isReadOnly && (
        <>
          <div className="flex items-center gap-2">
            <Controller
              name={input.field.key}
              render={({ field }) => (
                <>
                  <Checkbox
                    id={`${view.key}-${input.id}-all-day-checkbox`}
                    checked={field.value?.all_day}
                    onClick={() => handleCheckboxAllDayChange(field.value, field.onChange)}
                  />
                  <Label htmlFor={`${view.key}-${input.id}-all-day-checkbox`}>
                    {t('components.views.form.date_time_input.all_day')}
                  </Label>
                  <Checkbox
                    id={`${view.key}-${input.id}-repeat-checkbox`}
                    checked={field.value?.repeat !== undefined}
                    onClick={() => handleCheckRepeatOptions(field.value, field.onChange)}
                  />
                  <Label htmlFor={`${view.key}-${input.id}-repeat-checkbox`}>
                    {t('components.views.form.date_time_input.repeat')}
                  </Label>
                </>
              )}
            />
          </div>
          {isRepeatOptionsOpen && !isReadOnly && (
            <DateTimeRepeatOptions
              dateFormat={dateFormat}
              getFormattedDate={getFormattedDate}
              fieldKey={input.field.key}
            />
          )}
        </>
      )}
    </>
  );
}
