const searchParamsOnAppLoad = new URLSearchParams(window.location.search);

export function isIframedByBuilder() {
  const referrerOrigin = document.referrer ? new URL(document.referrer).origin : null;
  const isBuilderOrigin = referrerOrigin === import.meta.env.PUBLIC_BUILDER_URL;
  const isIframed = window.self !== window.top;

  return isIframed && isBuilderOrigin;
}

export function isPageEditorPreview() {
  return isIframedByBuilder() && searchParamsOnAppLoad?.get('preview') === 'true';
}

export function isPageEditor() {
  return isIframedByBuilder() && searchParamsOnAppLoad?.get('pageEditor') === 'true';
}

export function isBuilderQuickView() {
  return isIframedByBuilder() && searchParamsOnAppLoad?.get('quickView') === 'true';
}

// Flag for all internal builder iframes, except for 'quick view' mode since it's just the full application in an iframe
export function isInternalBuilderIframe() {
  return isIframedByBuilder() && !isBuilderQuickView();
}
