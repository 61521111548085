import { useTranslation } from 'react-i18next';

import { type ChartCalculation, type ChartRecord } from '@/types/schema/views/ReportView';

export const CALCULATION_PREFIX = 'calc_';
export const LIMIT_BEFORE_GROUPING = 10;

export function useChartHelpers() {
  const [t] = useTranslation();

  // Aggregates the data by the group_0 field
  const aggregateData = (data: ChartRecord[]) => {
    const aggregated = data.reduce<Record<string, ChartRecord>>((acc, item) => {
      const group = item.group_0 || t('components.views.report.others');
      if (!acc[group]) {
        acc[group] = { group_0: group, calc_0: 0, raw_0: 0 };
      }

      // Ensure the fields are numbers before performing arithmetic
      if (typeof acc[group].calc_0 === 'number' && typeof item.calc_0 === 'number') {
        acc[group].calc_0 += item.calc_0;
      }

      if (typeof acc[group].raw_0 === 'number' && typeof item.raw_0 === 'number') {
        acc[group].raw_0 += item.raw_0;
      }

      return acc;
    }, {});

    return Object.values(aggregated);
  };

  // Limits the records to a certain number of items
  const limitRecords = (data: ChartRecord[], limit = LIMIT_BEFORE_GROUPING) => {
    if (data.length <= limit) {
      return data;
    }

    const sorted = [...data].sort((a, b) => (b.calc_0 as number) - (a.calc_0 as number));
    const limited = sorted.slice(0, limit);
    const others = sorted.slice(limit).reduce(
      (acc, item) => ({
        group_0: t('components.views.report.others'),
        calc_0: (acc.calc_0 as number) + (item.calc_0 as number),
        raw_0: (acc.raw_0 as number) + (item.raw_0 as number)
      }),
      { group_0: t('components.views.report.others'), calc_0: 0, raw_0: 0 }
    );

    return [...limited, others];
  };

  // Get the calculation key with the maximum value. It's useful to set the YAxis domain
  const getMaxValueCalculation = (
    chartCalculations: ChartCalculation[],
    calculationRecords: ChartRecord[]
  ) => {
    let maxCalculationKey = `${CALCULATION_PREFIX}0`;
    let maxCalculationValue = 0;

    chartCalculations.forEach((calculation, index) => {
      const calculationKey = `${CALCULATION_PREFIX}${index}`;
      const calculationValues = calculationRecords.map(
        (record) => record[calculationKey] as number
      );
      const calculationMaxValue = Math.max(...calculationValues);

      if (calculationMaxValue > maxCalculationValue) {
        maxCalculationKey = calculationKey;
        maxCalculationValue = calculationMaxValue;
      }
    });

    return maxCalculationKey;
  };

  // Get the records data in percentage format
  const getRecordsInPercentage = (records: ChartRecord[]) => {
    // Calculate the percentage per row only in the numeric values
    const recordsInPercentage = records.map((record) => {
      const currentRecord = { ...record };

      // Filter only the numeric values
      const calculationValues = Object.keys(currentRecord).filter(
        (key) => typeof currentRecord[key] === 'number' && key.startsWith(CALCULATION_PREFIX)
      );

      // Calculate the total sum of the numeric values
      const totalSum = calculationValues.reduce(
        (acc, key) => acc + (currentRecord[key] as number),
        0
      );

      // Calculate the percentage of each numeric value
      calculationValues.forEach((key) => {
        currentRecord[key] = (((currentRecord[key] as number) / totalSum) * 100).toFixed(2);
      });

      return currentRecord;
    });

    return recordsInPercentage;
  };

  // Transform negative values to zero
  const transformNegativeValuesToZero = (records: ChartRecord[]) =>
    records.map((record) => {
      const currentRecord = { ...record };

      Object.keys(currentRecord).forEach((key) => {
        if (
          typeof currentRecord[key] === 'number' &&
          key.startsWith(CALCULATION_PREFIX) &&
          currentRecord[key] < 0
        ) {
          currentRecord[key] = 0;
        }
      });

      return currentRecord;
    });

  // Filters the empty groups from the records
  const filterEmptyGroups = (records: ChartRecord[]) =>
    records.filter((record) => {
      const calculationValues = Object.keys(record).filter(
        (key) => typeof record[key] === 'number' && key.startsWith(CALCULATION_PREFIX)
      );

      return calculationValues.some((key) => record[key] !== null);
    });

  // Limit decimal places to 2
  const limitDecimalPlaces = (chartRecords: ChartRecord[]) => {
    if (typeof chartRecords[0].calc_0 === 'number') {
      return chartRecords.map((record) => ({
        ...record,
        calc_0: parseFloat(Number(record.calc_0).toFixed(2)),
        raw_0: parseFloat(Number(record.raw_0).toFixed(2))
      }));
    }
    return chartRecords;
  };

  return {
    aggregateData,
    limitRecords,
    filterEmptyGroups,
    getMaxValueCalculation,
    getRecordsInPercentage,
    transformNegativeValuesToZero,
    limitDecimalPlaces
  };
}
