import { Rating, RichTextRenderer } from '@knack/asterisk-react';

import {
  type TableDisplayRuleIcon,
  type TableView,
  type TableViewColumn
} from '@/types/schema/views/TableView';
import { cn } from '@/utils/tailwind';
import { LabelWithIcon } from '@/components/LabelWithIcon';
import { useViewContext } from '@/components/views/ViewContext';

interface TableViewCellContentProps {
  shouldHideContent?: boolean;
  column: TableViewColumn;
  icon: TableDisplayRuleIcon & { color: string };
  value: string;
  children?: React.ReactNode;
  shouldCenterContent?: boolean;
}

export function TableViewCellContent({
  icon,
  column,
  shouldHideContent,
  value,
  children,
  shouldCenterContent
}: TableViewCellContentProps) {
  const fieldKey = column.field?.key;
  const { sourceTable } = useViewContext<TableView>();

  const field = sourceTable?.fields.find((f) => f.key === fieldKey);

  // If it's a rating field, we must render the stars and not the raw value
  const isRatingField = field?.type === 'rating';

  return (
    <div
      className={cn('flex items-center', {
        hidden: shouldHideContent,
        'justify-center': shouldCenterContent || column.align === 'center',
        'justify-start': column.align === 'left' && !shouldCenterContent,
        'justify-end': column.align === 'right' && !shouldCenterContent
      })}
    >
      <LabelWithIcon
        icon={icon?.icon}
        iconPosition={icon?.align || 'left'}
        iconStyle={{ color: icon.color }}
      >
        {!isRatingField ? (
          children || <RichTextRenderer dangerouslySetInnerHTML={{ __html: value }} />
        ) : (
          <Rating
            ratingNumber={Number(value)}
            maxRatingNumber={parseInt(field?.format.max, 10)}
            allowHalf={field?.format.allow_half}
          />
        )}
      </LabelWithIcon>
    </div>
  );
}
