import { type BryntumCalendarProps } from '@bryntum/calendar-react';

import { type CalendarProps } from './type';

const getCalendarDefaultProps = ({
  weekStartDay,
  today,
  onToggle,
  dayEndTime,
  dayStartTime,
  hideNonWorkingDays,
  mode,
  disableEventEditFeature,
  disableEventTooltipFeature,
  disableEventCreate,
  canDelete
}: Partial<CalendarProps>): BryntumCalendarProps => ({
  id: `calendar_${Date.now().toString()}`,
  date: today,
  sidebar: false,
  height: 500,
  modes: {
    year: false,
    agenda: true,
    day: {
      dayStartTime,
      dayEndTime
    },
    week: {
      dayStartTime,
      dayEndTime
    },
    month: {
      autoRowHeight: true,
      maxEventsPerCell: 6
    }
  },
  mode,
  tbar: {
    items: {
      toggleSidebar: null,
      modeSelector: { weight: 100 },
      todayButton: { weight: 150 },
      prevButton: { weight: 200 },
      viewDescription: { weight: 300 },
      nextButton: { weight: 400 },
      spacer: { weight: 450 },
      spacer2: {
        text: '',
        flex: 1,
        cls: 'border-none bg-card',
        weight: 180
      },
      filter: {
        icon: 'b-fa b-fa-list',
        weight: 500,
        onAction: onToggle
      }
    }
  },
  weekStartDay,
  hideNonWorkingDays,
  // additional feature for future scope
  eventCopyPasteFeature: {
    disabled: true
  },
  eventEditFeature: {
    editorConfig: {
      autoClose: false,
      bbar: {
        items: {
          // disabled delete button from edit form view
          deleteButton: false
        }
      }
    },
    // removes all the default bryntum default form fields
    items: {
      resourceField: false,
      endDateField: false,
      endTimeField: false,
      nameField: false,
      startDateField: false,
      startTimeField: false,
      repeatField: false,
      allDay: false,
      recurrenceCombo: false,
      editRecurrenceButton: false
    }
  },
  eventMenuFeature: {
    items: {
      // disabled delete option from calendar view
      deleteEvent: null,
      // additional feature for future scope
      duplicate: null,
      editEvent: !disableEventEditFeature
    }
  },
  // details view controls
  eventTooltipFeature: {
    disabled: disableEventTooltipFeature,
    tooltip: {
      tools: {
        // details view action
        // edit: null,
        ...(!canDelete && {
          delete: null
        })
      }
    }
  },
  autoCreate: !disableEventCreate,
  dragFeature: {
    creatable: !disableEventCreate,
    draggable: !disableEventEditFeature
  },
  scheduleMenuFeature: {
    disabled: disableEventCreate
  }
});

export { getCalendarDefaultProps };
