import { Controller, useFormContext, type FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BsDot as DotIcon } from 'react-icons/bs';
import { HiExclamationTriangle as ErrorIcon, HiCheck as SuccessIcon } from 'react-icons/hi2';
import { InputPassword, Label } from '@knack/asterisk-react';
import { isEmpty } from 'lodash';

import { type FormViewPasswordInput } from '@/types/schema/views/form/Password';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';
import { getPasswordValidationState } from './helper';

export function PasswordInput({ input }: { input: FormViewPasswordInput }) {
  const [t] = useTranslation();
  const { view } = useViewContext();
  const { action_authenticate: actionAuthenticate } = input;
  const { data: applicationData } = useApplicationQuery();
  const {
    formState: { errors },
    watch
  } = useFormContext();

  const passwordValue = watch(`${input.field.key}.password`);
  const passwordConditions = getPasswordValidationState(
    passwordValue,
    applicationData?.settings?.passwordOptions
  );

  const isEveryConditionSuccess =
    passwordConditions &&
    Object.values(passwordConditions).every((condition) => condition === 'success');
  const shouldConfirmCurrentPassword = actionAuthenticate;

  const shouldShowPasswordHints = !isEmpty(passwordConditions);

  const validationPasswordMapping = {
    minimumCharacter: t('components.views.form.password_input.minimum_characters_error'),
    requireNoCommon: t('components.views.form.password_input.no_common_words_error'),
    requireNumber: t('components.views.form.password_input.need_one_number_error'),
    specialCharacter: t('components.views.form.password_input.need_one_special_character_error'),
    requireLowerCase: t('components.views.form.password_input.need_one_lowercase_error'),
    requireUpperCase: t('components.views.form.password_input.need_one_uppercase_error')
  } as const;

  type ValidationPasswordMapping = keyof typeof validationPasswordMapping;

  const activePasswordValidationRules =
    (passwordConditions && Object.keys(passwordConditions)) || [];

  const passwordHintOptions = activePasswordValidationRules
    ? activePasswordValidationRules.map((key: string) => ({
        key,
        message: validationPasswordMapping[key as ValidationPasswordMapping]
      }))
    : [];

  const passwordInputError = errors[input.field.key] as FieldErrors<{
    password: string;
    confirmPassword: string;
  }>;

  return (
    <div>
      {shouldConfirmCurrentPassword && (
        <Controller
          defaultValue=""
          name={`${input.field.key}.currentPassword`}
          render={({ field }) => (
            <div className="mb-4 space-y-2">
              <InputPassword
                {...field}
                id={`${view.key}-${input.id}`}
                data-testid={`${input.id}-current-password-input`}
                intent={errors[input.field.key] ? 'destructive' : 'default'}
                placeholder={t('components.views.form.password_input.enter_your_current_password')}
                className="p-2"
              />
              <FormErrorMessage errors={errors} name={input.field.key} />
            </div>
          )}
        />
      )}
      <Controller
        defaultValue=""
        name={`${input.field.key}.password`}
        render={({ field }) => (
          <div className="space-y-2">
            {shouldConfirmCurrentPassword && (
              <Label
                htmlFor={`${input.id}-new-password`}
                className="font-medium"
                intent={errors[input.field.key] ? 'destructive' : undefined}
                isRequired
              >
                {t('components.views.form.password_input.new_password')}
              </Label>
            )}
            <InputPassword
              {...field}
              id={shouldConfirmCurrentPassword ? `${input.id}-new-password` : input.id}
              data-testid={`${input.id}-password-input`}
              intent={errors[input.field.key] ? 'destructive' : 'default'}
              placeholder={t('components.views.form.password_input.enter_your_password')}
              className="p-2"
            />
            <FormErrorMessage errors={errors} name={`${input.field.key}.password`} />
          </div>
        )}
      />
      <Controller
        defaultValue=""
        name={`${input.field.key}.confirmPassword`}
        render={({ field }) => (
          <div className="mt-4 space-y-2">
            <Label
              htmlFor={`${input.id}-confirm-password-input`}
              className="font-medium"
              intent={errors[input.field.key] ? 'destructive' : undefined}
              isRequired
            >
              {t(
                `components.views.form.password_input.${shouldConfirmCurrentPassword ? 'confirm_new_password' : 'confirm_password'}`
              )}
            </Label>
            <InputPassword
              id={`${input.id}-confirm-password-input`}
              data-testid={`${input.id}-confirm-password-input`}
              intent={errors[input.field.key] ? 'destructive' : 'default'}
              className="p-2"
              placeholder={t('components.views.form.password_input.enter_your_password')}
              {...field}
            />
            <FormErrorMessage errors={errors} name={`${input.field.key}.confirmPassword`} />
            <FormErrorMessage errors={errors} name={`${input.field.key}.currentPassword`} />
          </div>
        )}
      />
      {(shouldShowPasswordHints || passwordInputError) && (
        <div className="mt-2">
          <p
            className={cn('text-xs text-subtle', {
              'text-destructive': passwordInputError?.password
            })}
          >
            {passwordInputError?.password && !isEveryConditionSuccess && (
              <ErrorIcon size={12} className="mr-1 inline-block text-destructive" />
            )}
            {isEveryConditionSuccess && shouldShowPasswordHints && (
              <SuccessIcon size={12} className="mr-1 inline-block text-success-default" />
            )}
            {shouldShowPasswordHints && t('components.views.form.password_input.must_contain')}
            {passwordHintOptions.length > 2 ? ':' : ' '}
            {passwordHintOptions.length <= 2 &&
              passwordHintOptions.map((hint, index) => (
                <span key={hint.key}>
                  {hint.message}{' '}
                  {index === 0 && passwordHintOptions.length > 1 && `${t('keywords.with')} `}
                </span>
              ))}
          </p>
          {passwordHintOptions.length > 2 && (
            <ul
              className={cn('mt-2 text-xs text-subtle', {
                'text-destructive': passwordInputError?.password
              })}
            >
              {passwordHintOptions.map((hint) => {
                const condition = passwordConditions?.[hint.key as ValidationPasswordMapping];

                return (
                  <li
                    key={hint.key}
                    className={cn('ml-2 flex items-center gap-2', {
                      'text-success-default': condition === 'success'
                    })}
                  >
                    {condition === 'success' ? <SuccessIcon size={12} /> : <DotIcon size={12} />}
                    {hint.message}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}
