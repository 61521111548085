import { useContext } from 'react';
import { ThemeProviderContext, type ChartConfig } from '@knack/asterisk-react';
import snakeCase from 'lodash/snakeCase';

import type { ChartData, ChartRecord, ReportViewChart } from '@/types/schema/views/ReportView';
import { useThemingContext } from '@/context/ThemingContext';
import { getColors } from './colors';
import { CALCULATION_PREFIX, LIMIT_BEFORE_GROUPING, useChartHelpers } from './useChartHelpers';

interface UseChartsParams {
  chart: ReportViewChart;
  chartData: ChartData;
}

interface UserChartsReturn {
  chartRecords: ChartRecord[];
  chartCalculationsFormat: string;
  chartConfig: ChartConfig;
  chartKeys: { [k: string]: unknown };
  hasMultipleGroups: boolean;
  maxCalculationChartKey: string;
}

export function useChart({ chart, chartData }: UseChartsParams): UserChartsReturn {
  const { theme } = useThemingContext();
  const { isDarkMode } = useContext(ThemeProviderContext);
  const {
    getMaxValueCalculation,
    transformNegativeValuesToZero,
    filterEmptyGroups,
    aggregateData,
    limitRecords,
    limitDecimalPlaces
  } = useChartHelpers();

  if (!chartData || !chartData.records || chartData.records.length === 0) {
    return {
      chartRecords: [],
      chartCalculationsFormat: '',
      chartKeys: {},
      chartConfig: {},
      hasMultipleGroups: false,
      maxCalculationChartKey: ''
    };
  }

  // A chart can have a maximum of 2 groups
  const hasMultipleGroups = !!chartData.records[0]?.group_1;
  const chartCalculations = chart.calculations;
  const shouldHideNegativeValues = chart.options.hide_negatives;
  const shouldHideEmptyGroups = chart.options.exclude_empties;
  const colors = getColors(theme, isDarkMode, chart.layout?.colors) || [];
  const chartCalculationsFormat =
    chartCalculations[0].field_type === 'currency' && chartCalculations[0].field_format
      ? chartCalculations[0].field_format.format
      : '';

  let chartConfig: ChartConfig = {};

  // Each calculation is a separate line on the chart
  let chartKeys = chartCalculations
    .map((calculation, index) => `${CALCULATION_PREFIX}${index}`)
    .reduce(
      (acc, key) => {
        acc[key] = {};
        return acc;
      },
      {} as { [k: string]: unknown }
    );

  chartConfig = chartCalculations.reduce((acc, calculation, index) => {
    acc[`${CALCULATION_PREFIX}${index}`] = {
      label: calculation.label,
      color: colors[index % colors.length] as string
    };
    return acc;
  }, {} as ChartConfig);

  let chartRecords = chartData.records;

  if (shouldHideNegativeValues) {
    chartRecords = transformNegativeValuesToZero(chartRecords);
  }

  if (shouldHideEmptyGroups) {
    chartRecords = filterEmptyGroups(chartRecords);
  }

  chartRecords = limitDecimalPlaces(chartRecords);
  const aggregatedData = aggregateData(chartRecords);
  const limitedData = limitRecords(aggregatedData, LIMIT_BEFORE_GROUPING);

  if (!hasMultipleGroups) {
    return {
      chartRecords: limitedData,
      chartCalculationsFormat,
      chartKeys,
      chartConfig,
      hasMultipleGroups,
      maxCalculationChartKey: getMaxValueCalculation(chartCalculations, chartData.records)
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: Record<string, any> = {};
  const labels: string[] = [];

  // Convert the chart data into a format that the chart component can understand
  chartRecords.forEach((record) => {
    const groupValue = record.group_0;
    const subGroupValue = record.group_1;
    const calcValue = record.calc_0;

    if (!result[groupValue]) {
      result[groupValue] = { group_0: groupValue };
    }

    result[groupValue][snakeCase(subGroupValue as string)] = calcValue;
    labels.push(subGroupValue as string);
  });

  chartRecords = Object.values(result);

  // Remove duplicate labels
  const chartLabels = [...new Set(labels)];

  chartKeys = Object.fromEntries(Object.entries(chartRecords[0]).slice(1));

  Object.keys(chartKeys).forEach((key, index) => {
    chartConfig[key] = {
      label: <span className="mr-2">{chartLabels[index]}</span>,
      color: colors[index % colors.length] as string
    };
  });

  return {
    chartRecords,
    chartCalculationsFormat,
    chartKeys,
    chartConfig,
    hasMultipleGroups,
    maxCalculationChartKey: getMaxValueCalculation(chartCalculations, chartData.records)
  };
}
