import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { type AppTheme, type ThemeMode } from '@knack/asterisk-react';

import { type LiveAppPageFull } from '@/types/schema/LiveAppPage';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import {
  type PageEditorItemToSelect,
  type PageEditorSelectedItem,
  type PageEditorUiOptions,
  type PageEditorUpdate
} from '@/pages/page/page-editor/helpers/types';

type MessageToBuilder =
  | {
      action: 'select';
      itemToSelect: PageEditorItemToSelect;
    }
  | {
      action: 'update';
      update: PageEditorUpdate;
    }
  | {
      action: 'start-add-view';
      columnId: string;
      sectionId: string;
      originViewKey?: LiveAppViewKey;
      position?: 'above' | 'below';
    }
  | {
      action: 'start-edit-view-input';
      viewKey: string;
      viewInputId: string;
    }
  | {
      action: 'start-delete-view-input';
      viewKey: string;
      viewInputId: string;
    }
  | {
      action: 'request-page-sync';
    };

type MessageFromBuilder =
  | {
      action: 'update';
      updatedPage: LiveAppPageFull;
      selectedItem?: PageEditorSelectedItem;
    }
  | {
      action: 'select';
      selectedItem: PageEditorSelectedItem;
    }
  | {
      action: 'page-sync';
      page: LiveAppPageFull;
      initialUiOptions: PageEditorUiOptions;
      initialSelectedItem?: PageEditorSelectedItem;
    }
  | {
      action: 'set-theme';
      theme: AppTheme;
    }
  | {
      action: 'set-theme-preview-mode';
      mode: ThemeMode;
    }
  | {
      action: 'ui-options';
      uiOptions: PageEditorUiOptions;
    }
  | {
      action: 'update-main-navigation';
    };

export type StartAddViewPayload = Omit<
  Extract<MessageToBuilder, { action: 'start-add-view' }>,
  'action'
>;

export type StartEditViewInputPayload = Omit<
  Extract<MessageToBuilder, { action: 'start-edit-view-input' }>,
  'action'
>;

export type StartDeleteViewInputPayload = Omit<
  Extract<MessageToBuilder, { action: 'start-delete-view-input' }>,
  'action'
>;

interface MessagingContextState {
  messageFromBuilder: MessageFromBuilder | null;
  sendMessageToBuilder: (message: MessageToBuilder) => void;
}

const MessagingContext = createContext<MessagingContextState>({
  messageFromBuilder: null,
  sendMessageToBuilder: () => {}
});

export function MessagingContextProvider({ children }: { children: React.ReactNode }) {
  const [messageFromBuilder, setMessageFromBuilder] = useState<MessageFromBuilder | null>(null);

  const sendMessageToBuilder = useCallback((message: MessageToBuilder) => {
    window.parent.postMessage(message, import.meta.env.PUBLIC_BUILDER_URL);
  }, []);

  const handleIncomingMessage = (event: MessageEvent) => {
    if (event.origin !== new URL(import.meta.env.PUBLIC_BUILDER_URL).origin) {
      return;
    }
    setMessageFromBuilder(event.data);
  };

  const contextValue = useMemo(
    () => ({ messageFromBuilder, sendMessageToBuilder }),
    [messageFromBuilder, sendMessageToBuilder]
  );

  useEffect(() => {
    window.addEventListener('message', handleIncomingMessage);
    return () => window.removeEventListener('message', handleIncomingMessage);
  }, []);

  return <MessagingContext.Provider value={contextValue}>{children}</MessagingContext.Provider>;
}

export const useMessagingContext = () => {
  const context = useContext(MessagingContext);
  if (!context) {
    throw new Error('useMessagingContext must be used within a MessagingProvider');
  }
  return context;
};
