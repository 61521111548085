import { useState } from 'react';
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiXMark as CloseIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Popover, Table, useToast } from '@knack/asterisk-react';
import { isAxiosError } from 'axios';
import { type z } from 'zod';

import { type FormViewFieldInput } from '@/types/schema/views/FormView';
import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { useUpdateRecordMutation } from '@/hooks/api/mutations/useUpdateRecordMutation';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { FormFieldInput } from '@/components/views/form/FormFieldInput';
import { getDynamicFormSchema } from '@/components/views/form/schema/helper';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';
import { TableViewCellContent } from './TableViewCellContent';
import { useTableDisplayRules } from './useTableDisplayRules';

interface InlineEditFormProps {
  input: FormViewFieldInput;
  record: FormattedViewRecord;
  column: TableViewColumn;
  cellClassName?: string;
}

export function InlineEditForm({ input, record, column, cellClassName }: InlineEditFormProps) {
  const [t] = useTranslation();
  const { theme } = useThemingContext();
  const { presentToast } = useToast();
  const {
    cellStyle: displayRuleCellStyle,
    icon,
    shouldHideContent
  } = useTableDisplayRules(record.values, column.rules);

  const { sourceTable, view } = useViewContext<TableView>();
  const { mutate: updateRecord, isPending } = useUpdateRecordMutation({ viewKey: view.key });

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const sourceTableFields = sourceTable.fields;

  const formSchema = getDynamicFormSchema({
    inputs: [input],
    sourceTableFields
  });

  type FormSchemaType = z.infer<typeof formSchema>;

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: { [input.field.key]: record.rawValues[column.field.key] }
  });

  const onSubmit: SubmitHandler<FormSchemaType> = (data) => {
    updateRecord(
      {
        data,
        recordId: record.values.id
      },
      {
        onSuccess: () => {
          presentToast({
            intent: 'success',
            title: t('components.views.table.record_updated', { fieldName: column.header })
          });
          setIsPopoverOpen(false);
        },
        onError: (error: Error) => {
          if (isAxiosError(error) && error.response) {
            const { field, message } = error.response.data.errors[0];
            form.setError(field, {
              message,
              type: 'schema'
            });
          }
        }
      }
    );
  };

  const handleClosePopover = () => {
    setIsPopoverOpen(false);
  };

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <Popover.Trigger asChild>
        <Table.Cell
          className={cn('cursor-pointer border-x-0', cellClassName, {
            '!border-x-2 border-y-2 border-emphasis': isPopoverOpen
          })}
          style={displayRuleCellStyle}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              e.preventDefault();
              setIsPopoverOpen(true);
            }
          }}
        >
          <TableViewCellContent
            value={record.values[column.field.key]}
            icon={icon}
            column={column}
            shouldHideContent={shouldHideContent}
          />
        </Table.Cell>
      </Popover.Trigger>
      <Popover.Content
        align="start"
        className={cn('min-w-[280px] space-y-3 p-3', {
          'rounded-tl-none': theme.appearance.corners === 'rounded'
        })}
        sideOffset={1}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            const targetElement = e.target as HTMLElement;
            const isGoogleAutocompleteList = targetElement.classList.value.includes('pac-');

            // Check if we are doing enter on the Google autocomplete list and don't close the popover
            if (isGoogleAutocompleteList) {
              e.preventDefault();
            }
          }
        }}
        onInteractOutside={(e) => {
          e.preventDefault();
          const targetElement = e.target as HTMLElement;
          const isGoogleAutocompleteList =
            targetElement.parentElement?.classList.value.includes('pac-');

          // Check if we are clicking on the Google autocomplete list and don't close the popover
          if (isGoogleAutocompleteList) {
            return;
          }

          setIsPopoverOpen(false);
        }}
      >
        <div className="flex items-center justify-between">
          <span className="font-semibold">
            {t('actions.edit')} {column.header}
          </span>
          <Button intent="minimal" size="sm" onClick={handleClosePopover} tabIndex={-1}>
            <CloseIcon size={14} className="shrink-0" />
          </Button>
        </div>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-3">
              <FormFieldInput input={input} isInlineEdit />
            </div>
            <div className="flex justify-end gap-2">
              <Button intent="minimal" size="sm" onClick={handleClosePopover}>
                {t('actions.cancel')}
              </Button>
              <Button intent="primary" size="sm" type="submit" disabled={isPending}>
                {isPending ? t('actions.saving') : t('actions.save')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </Popover.Content>
    </Popover>
  );
}
