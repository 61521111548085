import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { type MenuViewLink } from '@/types/schema/views/MenuView';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { getApplicationBasePath } from '@/utils/application';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { LabelWithIcon } from '@/components/LabelWithIcon';

interface MenuLinkContentProps {
  link: MenuViewLink;
}

export function MenuLinkContentWithRef(
  { link, ...props }: MenuLinkContentProps,
  ref: React.Ref<HTMLAnchorElement>
) {
  const { getPagePathBySlug } = usePageHelpers();

  const getLinkTextWithIcon = () => {
    const icon = link.icon?.icon || undefined;
    const iconPosition = link.icon?.align || 'left';

    return (
      <LabelWithIcon icon={icon} iconPosition={iconPosition} spacing="md">
        {link.name}
      </LabelWithIcon>
    );
  };

  // We don't want any links to be clickable/linkable in the builder
  if (isInternalBuilderIframe()) {
    return <span {...props}>{getLinkTextWithIcon()}</span>;
  }

  if (link.type === 'url') {
    return (
      <a
        ref={ref}
        href={link.url}
        target={link.new_window ? '_blank' : undefined}
        rel={link.new_window ? 'noreferrer' : undefined}
        {...props}
      >
        {getLinkTextWithIcon()}
      </a>
    );
  }

  const scenePath = getPagePathBySlug(link.scene);

  if (link.new_window) {
    const fullPagePath = `${window.location.origin}${getApplicationBasePath()}${scenePath}`;

    return (
      <a ref={ref} href={fullPagePath} target="_blank" rel="noreferrer" {...props}>
        {getLinkTextWithIcon()}
      </a>
    );
  }

  return (
    <Link ref={ref} to={scenePath} {...props}>
      {getLinkTextWithIcon()}
    </Link>
  );
}

export const MenuLinkContent = forwardRef<HTMLAnchorElement, MenuLinkContentProps>(
  MenuLinkContentWithRef
);
