import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Calendar, EventModel } from '@bryntum/calendar';
import { BryntumCalendar } from '@bryntum/calendar-react';
import { useToast } from '@knack/asterisk-react';

import { getCalendarDefaultProps } from './calendarConfig';

import './calendar.css';

import { type CalendarView } from '@/types/schema/views/calendarView';
import { useDeleteRecordMutation } from '@/hooks/api/mutations/useDeleteRecordMutation';
import { useUpdateRecordMutation } from '@/hooks/api/mutations/useUpdateRecordMutation';
import { useViewMultipleRecordsQuery } from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { getDefaultDatePayload } from '@/components/views/form/inputs/date-time/utils';
import { getFormViewInputs } from '@/components/views/form/utils';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';
import { CalendarForm } from './CalendarForm';
import { CalendarUtils } from './CalendarUtils';
import {
  canFieldStoreDateValues,
  getBryntumEvents,
  getFilters,
  mappedMode,
  mappedWeekStartDay,
  someDeep
} from './helper';
import { type CalendarRef } from './type';

export function CalendarViewRender() {
  const { theme } = useThemingContext();
  const { view, sourceTable } = useViewContext<CalendarView>();
  const calendarRef = useRef<BryntumCalendar | null>(null);
  const previousMode = useRef('week');
  const [calendarInstance, setCalendarInstance] = useState<Calendar>();
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
  });
  const { mutate: deleteRecord } = useDeleteRecordMutation();
  const { mutate: updateRecord } = useUpdateRecordMutation({
    viewKey: view.key
  });
  const { presentToast } = useToast();
  const formRef = useRef<CalendarRef>(null);
  const canDelete = someDeep(view.details.columns, 'type', 'delete');
  const [t] = useTranslation();

  const tableKey = view.source.object;

  const { data } = useViewMultipleRecordsQuery({
    objectKey: tableKey,
    viewKey: view.key,
    options: {
      filters: getFilters({ ...dateRange, key: view.events.event_field.key })
    }
  });
  const eventKey = view.events.event_field.key;
  const labelKey = view.events.label_field.key;

  const events = getBryntumEvents({
    tableData: data?.records || [],
    eventField: eventKey,
    labelField: labelKey,
    sourceTable,
    event_color_default: view.events.event_color_default,
    event_colors: view.events.event_colors
  });

  const transformedViewData = useMemo(
    () => ({
      mode: view.events.display_type === 'calendar' ? mappedMode[view.events.view] : 'agenda',
      weekStartDay: mappedWeekStartDay[view.events.week_start],
      hideNonWorkingDays: !!view.events.exclude_weekends,
      dayStartTime: view.events.time_min || '0:00',
      dayEndTime: view.events.time_max || '24:00',
      disableEventEditFeature: !view.events.allow_edit,
      disableEventTooltipFeature: !view.events.show_details,
      disableEventCreate: !view.events.allow_add,
      canDelete
    }),
    [view, canDelete]
  );

  const handleToggle = () => {
    if (calendarRef.current) {
      const currentMode = calendarRef.current.instance.mode;
      if (currentMode !== 'agenda') {
        previousMode.current = currentMode;
        calendarRef.current.instance.mode = 'agenda';
      } else {
        calendarRef.current.instance.mode = previousMode.current;
      }
    }
  };

  const handleDelete = async (recordId: string) =>
    new Promise((resolve) => {
      deleteRecord(
        {
          viewKey: view.key,
          recordId
        },
        {
          onSuccess: () => {
            presentToast({
              title: t('components.views.table.record_delete_success')
            });
            resolve(false);
          },
          onError: () => {
            presentToast({
              title: t('components.views.table.record_delete_error'),
              intent: 'destructive'
            });
            resolve(false);
          }
        }
      );
    });

  const handleEdit = async (
    newStartDate: Date,
    newEndDate: Date,
    eventRecord: EventModel & { data?: { realEventId: string } }
  ) =>
    new Promise((resolve) => {
      const eventField = sourceTable.fields.find((field) => field.key === eventKey);
      if (eventField && eventRecord.data?.realEventId && canFieldStoreDateValues(eventField)) {
        const eventFormat = eventField.format;

        const formatedDate = getDefaultDatePayload(eventFormat, {
          startDate: newStartDate,
          endDate: newEndDate,
          eventKey
        });
        updateRecord(
          {
            data: { [eventKey]: formatedDate },
            recordId: eventRecord.data.realEventId
          },
          {
            onSuccess: (updatedRecord) => {
              resolve(updatedRecord);
            },
            onError: () => resolve(false)
          }
        );
      } else {
        resolve(false);
      }
    });

  const calendarProps = useMemo(
    () =>
      getCalendarDefaultProps({
        today: new Date(),
        onToggle: handleToggle,
        ...transformedViewData
      }),
    [transformedViewData]
  );

  useEffect(() => {
    if (calendarRef.current?.instance) {
      setCalendarInstance(calendarRef.current?.instance);
    }
  }, [calendarRef]);

  return (
    <div data-kn="calendar-view" data-rounded={theme.appearance.corners === 'rounded'}>
      <CalendarUtils calendarInstance={calendarInstance} />
      <BryntumCalendar
        {...calendarProps}
        ref={calendarRef}
        events={events}
        onBeforeEventEditShow={({ eventRecord, editor }) => {
          let eventFormValue;

          // get form values if it is an existing event
          if (!editor.record.hasGeneratedId) {
            const record = eventRecord as ViewRecord;
            const formViewInputs = getFormViewInputs(view.form.groups);
            const formValueMap = new Map();
            formViewInputs.forEach((inputs) => {
              if (inputs.type !== 'divider' && inputs.type !== 'section_break') {
                const inputKey = inputs.field.key;
                if (inputKey) {
                  formValueMap.set(inputKey, record?.[inputKey]);
                }
              }
            });
            formValueMap.set('id', record.id);

            eventFormValue = Object.fromEntries(formValueMap);
          }

          // render form
          editor.setConfig({
            items: {
              mainForm: {
                type: 'widget',
                name: 'mainForm',
                weight: 1,
                html: (
                  <CalendarForm
                    view={view}
                    sourceTable={sourceTable}
                    recordValues={eventFormValue}
                    startDate={new Date(eventRecord.startDate)}
                    endDate={new Date(eventRecord.endDate)}
                    eventKey={eventKey}
                    ref={formRef}
                  />
                )
              }
            }
          });
        }}
        onBeforeEventSave={async () => !!(await formRef.current?.submitForm())}
        onAfterEventEdit={({ action }) => {
          if (action === 'cancel') {
            formRef.current?.cancelForm();
          }
        }}
        onBeforeDragResizeEnd={async ({ newStartDate, newEndDate, eventRecord }) =>
          !!(await handleEdit(newStartDate, newEndDate, eventRecord))
        }
        onBeforeDragMoveEnd={async ({ newStartDate, newEndDate, eventRecord }) =>
          !!(await handleEdit(newStartDate, newEndDate, eventRecord))
        }
        onBeforeEventDelete={async ({ eventRecords }) =>
          !!(await handleDelete(String(eventRecords[0].id)))
        }
        listeners={{
          dateRangeChange({ new: { startDate, endDate } }) {
            setDateRange({ startDate, endDate });
          }
        }}
      />
    </div>
  );
}
