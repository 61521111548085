import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { type KnackFieldKey } from '@/types/schema/fields/KnackField';
import { type ListView } from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type TableView } from '@/types/schema/views/TableView';
import { type ViewFilters } from '@/hooks/useViewFilters';

export type ViewParam = keyof ViewQueryParams;

export type ViewSortOrder = 'asc' | 'desc';

export interface ViewQueryParams {
  rowsPerPage: string | null;
  page: number | null;
  search: string | null;
  filters: ViewFilters | null;
  mapFilters: ViewFilters | null;
  allFilters: ViewFilters | null;
  sortField?: KnackFieldKey | null;
  sortOrder?: ViewSortOrder;
}

export type ViewWithSearchParams = TableView | ListView | MapView;

const getDefaultTableViewSorting = (view: TableView) => {
  const columnFieldKeys: KnackFieldKey[] = [];
  const viewSourceSorting = view.source?.sort;

  view.columns.forEach((column) => {
    // Only field columns can be sorted - skip Action Columns
    if (column.type === 'field') {
      columnFieldKeys.push(column.field.key);
    }
  });

  // If the column is not visible in the element, it can't be sorted by it
  const availableSourceSorting = viewSourceSorting.filter((sort) =>
    columnFieldKeys.includes(sort.field)
  );

  const sortField = availableSourceSorting.length > 0 ? availableSourceSorting[0]?.field : null;
  const sortOrder: ViewSortOrder =
    availableSourceSorting.length > 0 ? availableSourceSorting[0]?.order : 'asc';

  return { sortField, sortOrder };
};

const getDefaultParams = (view: TableView | ListView | MapView): ViewQueryParams => ({
  rowsPerPage: view.rows_per_page,
  page: 1,
  search: '',
  filters: null,
  mapFilters: null,
  allFilters: null,
  ...(view.type === 'table' && getDefaultTableViewSorting(view))
});

const getFiltersFromParams = (
  filtersParam: string
): {
  filters: ViewFilters | null;
  mapFilters: ViewFilters | null;
  allFilters: ViewFilters | null;
} => {
  if (!filtersParam) return { filters: null, mapFilters: null, allFilters: null };

  const parsedFilters: ViewFilters = JSON.parse(filtersParam);

  const mapRules = parsedFilters.rules.filter((rule) => rule.operator === 'near');
  const filtersRules = parsedFilters.rules.filter((rule) => rule.operator !== 'near');

  return {
    filters: filtersRules.length > 0 ? { ...parsedFilters, rules: filtersRules } : null,
    mapFilters: mapRules.length > 0 ? { ...parsedFilters, rules: mapRules } : null,
    allFilters: parsedFilters
  };
};

export function useViewSearchParams(view: ViewWithSearchParams) {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    rowsPerPage,
    sortField,
    sortOrder,
    allFilters,
    mapFilters,
    search: searchValueFromParams,
    page: currentPage,
    filters: activeViewFilters
  } = useMemo(() => {
    const defaultParams = getDefaultParams(view);
    const viewParams = Object.fromEntries(searchParams.entries());
    if (!viewParams) return defaultParams;

    return Object.keys(viewParams).reduce((accumulatedParams, param) => {
      if (param.startsWith(`${view.key}_`)) {
        const extractedParam = param.substring(view.key.length + 1);
        const value =
          extractedParam === 'filters'
            ? getFiltersFromParams(viewParams[param])
            : viewParams[param];

        const filters = extractedParam === 'filters' ? value : {};
        return {
          ...accumulatedParams,
          [extractedParam]: value,
          ...filters
        };
      }
      return accumulatedParams;
    }, defaultParams);
  }, [searchParams, view]);

  const setViewParam = (newParams: Partial<ViewQueryParams>) => {
    Object.entries(newParams).forEach(([key, value]) => {
      const prefixedKey = `${view.key}_${key as ViewParam}` as const;
      if (value === null) {
        searchParams.delete(prefixedKey);
        return;
      }
      if (value) {
        if (key === 'filters') {
          searchParams.set(prefixedKey, JSON.stringify(value));
          return;
        }
        if (typeof value === 'number') {
          searchParams.set(prefixedKey, value.toString());
          return;
        }
        searchParams.set(prefixedKey, value as string);
      }
    });
    setSearchParams(searchParams, { replace: true });
  };

  return {
    rowsPerPage,
    sortField,
    sortOrder,
    allFilters,
    mapFilters,
    searchValueFromParams,
    currentPage,
    activeViewFilters,
    setViewParam
  };
}
