import {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
  type ReactNode
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '@knack/asterisk-react';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { type Session } from '@/types/session';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { useSessionContext } from './SessionContext';

type PageSegment = {
  slug: string | null;
  recordId: string | null; // ID of the record being viewed or edited (e.g. in a Details view)
};

type PageContextState = {
  activePage: LiveAppPage | null;
  activePageRecordId: string | null;
  protectedPageSlug: string | null;
  pageSegments: PageSegment[];
};

type PageContextProviderProps = {
  pages: LiveAppPage[];
  homePage: LiveAppPage;
  children: ReactNode;
};

const PageContext = createContext<PageContextState>({
  activePage: null,
  activePageRecordId: null,
  protectedPageSlug: null,
  pageSegments: []
});

function getPageSegmentsInPath(pathname: string | undefined, pages: LiveAppPage[]) {
  if (!pathname || !pages) return [];

  const pathSegments = pathname.split('/').filter(Boolean);
  const pageSegmentsInPath: PageSegment[] = [];

  // Build an array of page segments by looping through the segments in the pathname
  pathSegments.forEach((segment: string, index: number, arrayOfSegments: string[]) => {
    // We ignore the segment if it doesn't match a valid page in the schema
    if (!pages.some((p) => p.slug === segment)) {
      return;
    }

    const pageSegment: PageSegment = {
      slug: segment,
      recordId: null
    };

    // We now need to check if the next segment exists, since it can be a record ID associated with the current segment
    const nextSegment = arrayOfSegments[index + 1] ? arrayOfSegments[index + 1] : null;
    if (nextSegment) {
      // Check if the next segment matches a valid page in the schema
      const isNextSegmentAPage = nextSegment && pages.some((p) => p.slug === nextSegment);

      // If next segment is not a page, it means it could be a record
      if (!isNextSegmentAPage) {
        const recordIdRegex = /[A-Za-z0-9]{24}$/i;
        const isNextSegmentARecord = recordIdRegex.test(nextSegment);

        if (isNextSegmentARecord) {
          pageSegment.recordId = nextSegment;
        }
      }
    }

    pageSegmentsInPath.push(pageSegment);
  });

  return pageSegmentsInPath;
}

function getAuthPage(page: LiveAppPage, pages: LiveAppPage[]) {
  if (page.type === 'user') {
    return null;
  }

  if (page.type === 'authentication') {
    return page;
  }

  // We traverse the parent pages until we find one that is an authentication page
  let parentSlug = page.parentSlug || null;
  while (parentSlug) {
    // eslint-disable-next-line @typescript-eslint/no-loop-func
    const parentPage = pages.find((p) => p.slug === parentSlug);

    if (!parentPage) {
      break;
    }

    if (parentPage.type === 'authentication') {
      return parentPage;
    }

    parentSlug = parentPage.parentSlug;
  }

  return null;
}

function hasPageAccess(page: LiveAppPage, user: Session['user']) {
  if (!page.limitProfileAccess) {
    return true;
  }
  return user.profileKeys?.some((profile) => page.allowedProfileKeys?.includes(profile));
}

export function PageContextProvider({ pages, homePage, children }: PageContextProviderProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const navigate = useNavigate();
  const { '*': pathname } = useParams();
  const session = useSessionContext();

  const [shouldRestrictAccess, setShouldRestrictAccess] = useState(false);

  const contextValue = useMemo(() => {
    const pageSegments = getPageSegmentsInPath(pathname, pages);
    let requestedPage: LiveAppPage | null = null;
    let authPage: LiveAppPage | null = null;
    let activePage: LiveAppPage | null = null;
    let activePageRecordId: string | null = null;
    let protectedPageSlug: string | null = null;

    // Get the correct requested page based on the segments in the path
    if (pageSegments.length) {
      const lastPageSegment = pageSegments[pageSegments.length - 1];
      requestedPage = pages.find((p) => p.slug === lastPageSegment.slug) ?? null;
    } else {
      // If there are no segments in the path it means that the requested page is the one set as 'homePage' in the schema
      requestedPage = pages.find((p) => p.slug === homePage.slug) ?? null;
    }

    // Default the active page to the requested page
    activePage = requestedPage;

    // If the page requires authentication, we need to determine if the user has access to it
    // Note: if viewing the page inside the builder, we ignore authentication requirements
    if (requestedPage?.requiresAuthentication && !isInternalBuilderIframe()) {
      authPage = getAuthPage(requestedPage, pages);

      // If there is an auth page and it's not the same as requested page, it means the page is protected
      if (authPage && authPage.key !== requestedPage.key) {
        protectedPageSlug = requestedPage.slug;
      }

      // If the user is logged in, we need to check if they have the proper role access to the page
      if (session?.user) {
        const userHasAccessToPage = hasPageAccess(requestedPage, session.user);

        if (userHasAccessToPage) {
          activePage = requestedPage;
        } else {
          activePage = homePage;
          setShouldRestrictAccess(true);
        }

        // Otherwise, if the user is not logged in, we redirect them to the auth page or home page
      } else if (requestedPage.type === 'user') {
        activePage = homePage;
        setShouldRestrictAccess(true);
      } else {
        activePage = authPage;
      }
    }

    // There might be a record id associated with the page, so we need to find it
    activePageRecordId = pageSegments.find((s) => s.slug === activePage?.slug)?.recordId ?? null;

    return {
      activePage,
      activePageRecordId,
      protectedPageSlug,
      pageSegments
    };
  }, [pathname, pages, homePage, session?.user]);

  // If the user doesn't have access to the page, we redirect them to the home page
  useLayoutEffect(() => {
    if (shouldRestrictAccess) {
      setShouldRestrictAccess(false);
      navigate('/');
      presentToast({
        title: t('errors.restricted_access'),
        intent: 'destructive'
      });
    }
  }, [navigate, presentToast, shouldRestrictAccess, t]);

  return (
    <PageContext.Provider key={session?.user.id} value={contextValue}>
      {children}
    </PageContext.Provider>
  );
}

export const usePageContext = () => {
  const pagesContext = useContext(PageContext);
  return pagesContext;
};
