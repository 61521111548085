import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiCheck as CheckIcon,
  HiOutlineLogout as LogOutIcon,
  HiDocument as PageIcon
} from 'react-icons/hi';
import {
  HiMoon as MoonIcon,
  HiMiniCog6Tooth as SettingsIcon,
  HiSun as SunIcon
} from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { Divider, DropdownMenu, ThemeProviderContext } from '@knack/asterisk-react';

import { useLogoutUserMutation } from '@/hooks/api/mutations/useLogoutUserMutation';
import { useEntryPagesQuery } from '@/hooks/api/queries/useEntryPagesQuery';
import { cn } from '@/utils/tailwind';
import { ProfileCard } from '@/components/ProfileCard';
import { useSessionContext } from '@/context/SessionContext';
import { useThemingContext } from '@/context/ThemingContext';

export function NavItemProfile() {
  const { data: entryPages } = useEntryPagesQuery();
  const userPages = entryPages?.filter((page) => page.type === 'user') || [];
  const [t] = useTranslation();
  const { theme } = useThemingContext();
  const session = useSessionContext();
  const logout = useLogoutUserMutation();
  const { currentMode, setCurrentMode } = useContext(ThemeProviderContext);

  const handleSignOut = () => {
    if (session) {
      logout.mutate();
    }
  };

  let appearanceIcon = <SettingsIcon size={20} data-testid="navigation-appearance" />;
  if (currentMode === 'light') {
    appearanceIcon = <SunIcon size={20} data-testid="navigation-appearance" />;
  } else if (currentMode === 'dark') {
    appearanceIcon = <MoonIcon size={20} data-testid="navigation-appearance" />;
  }

  const appearanceItems = (
    <>
      <DropdownMenu.Item className="gap-2" onClick={() => setCurrentMode('light')}>
        <SunIcon className="shrink-0" data-testid="navigation-appearance" />
        <span>{t('navigation.light_mode')}</span>
        <DropdownMenu.Shortcut>{currentMode === 'light' && <CheckIcon />}</DropdownMenu.Shortcut>
      </DropdownMenu.Item>
      <DropdownMenu.Item className="gap-2" onClick={() => setCurrentMode('dark')}>
        <MoonIcon className="shrink-0" data-testid="navigation-appearance" />
        <span>{t('navigation.dark_mode')}</span>
        <DropdownMenu.Shortcut>{currentMode === 'dark' && <CheckIcon />}</DropdownMenu.Shortcut>
      </DropdownMenu.Item>
      <DropdownMenu.Item className="gap-2" onClick={() => setCurrentMode('system')}>
        <SettingsIcon className="shrink-0" data-testid="navigation-appearance" />
        <span>{t('navigation.system_default')}</span>
        <DropdownMenu.Shortcut>
          {currentMode === 'system' && <CheckIcon className="shrink-0" />}
        </DropdownMenu.Shortcut>
      </DropdownMenu.Item>
    </>
  );

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger
        aria-label={t('navigation.appearance_menu')}
        className={cn('flex shrink-0 justify-center overflow-hidden p-1 hover:bg-subtle', {
          'rounded-lg': theme.appearance.corners === 'rounded'
        })}
        data-testid="sidebar-profile-options-button"
      >
        {session ? (
          <ProfileCard
            firstName={session?.user?.name?.firstName}
            email={session?.user?.email || ''}
            avatarUri={session?.user?.picture || ''} // picture will be placed only in Google SSO
          />
        ) : (
          theme.shouldAllowModeToggle && appearanceIcon
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="min-w-[calc(100vw-32px)] sm:min-w-[260px]"
        side="top"
        collisionPadding={16}
      >
        {userPages.length > 0 && (
          <>
            {userPages.map((page) => (
              <DropdownMenu.Item key={page.key} asChild className="gap-2">
                <Link to={page.slug}>
                  <PageIcon size={16} className="shrink-0" />
                  {page.name}
                </Link>
              </DropdownMenu.Item>
            ))}
            <Divider className="my-2" />
          </>
        )}
        {session ? (
          <>
            {theme.shouldAllowModeToggle && (
              <DropdownMenu.SubMenu>
                <DropdownMenu.SubTrigger className="gap-2">
                  <SettingsIcon className="shrink-0" />
                  <span>{t('navigation.appearance')}</span>
                </DropdownMenu.SubTrigger>
                <DropdownMenu.SubContent className="w-48">
                  {appearanceItems}
                </DropdownMenu.SubContent>
              </DropdownMenu.SubMenu>
            )}
            <DropdownMenu.Item onSelect={handleSignOut} className="gap-2">
              <LogOutIcon className="shrink-0" data-testid="auth-logout-button" />
              <span>{t('actions.sign_out')}</span>
            </DropdownMenu.Item>
          </>
        ) : (
          appearanceItems
        )}
      </DropdownMenu.Content>
    </DropdownMenu>
  );
}
