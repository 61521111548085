import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { type RuleCriteria } from '@/types/schema/LiveAppView';
import { type FormViewRules } from '@/types/schema/views/FormView';
import { isEveryCriteriaMet } from '@/utils/criteriaRules';
import { useViewContext } from '@/components/views/ViewContext';
import { usePageSegmentsHelpers } from './helpers/usePageSegmentsHelpers';

interface HandleSubmitRulesParams {
  data: { [key: string]: unknown };
  rules: FormViewRules['submits'];
}

export function useViewRules() {
  const navigate = useNavigate();

  const { sourceTable } = useViewContext();
  const { getChildPagePathFromSlug, getPagePathFromSlug, getPreviousPagePath } =
    usePageSegmentsHelpers();

  const [applicableSubmitRuleValues, setApplicableSubmitRuleValues] = useState({
    confirmationMessage: '',
    shouldShowReloadButton: false,
    shouldReloadAutomatically: false
  });

  const sourceTableFields = useMemo(() => sourceTable?.fields ?? [], [sourceTable]);

  const checkCriteriaRules = useCallback(
    (data: { [key: string]: unknown }, criteria: RuleCriteria[]) =>
      isEveryCriteriaMet(data, sourceTableFields, criteria),
    [sourceTableFields]
  );

  const handleSubmitRules = ({ data, rules }: HandleSubmitRulesParams) => {
    const nonDefaultSubmitRules = rules.filter((rule) => !rule.is_default);

    let applicableRule = nonDefaultSubmitRules.find(
      (rule) => isEveryCriteriaMet(data, sourceTableFields, rule.criteria) || !rule.criteria
    );

    // If no user rule is applicable, use the default submit rule
    if (!applicableRule) {
      applicableRule = rules.find((rule) => rule.is_default);
    }

    if (!applicableRule) return;

    const {
      action,
      url,
      existing_page: existingPage,
      scene,
      message,
      reload_show: shouldShowReloadButton,
      reload_auto: shouldReloadAutomatically
    } = applicableRule;

    setApplicableSubmitRuleValues((prev) => ({
      ...prev,
      shouldShowReloadButton: shouldShowReloadButton || false,
      shouldReloadAutomatically: shouldReloadAutomatically || false
    }));

    let formattedUrl = url || '/';

    if (action === 'url' && url) {
      if (!url.includes('http')) {
        formattedUrl = `//${url}`;
      } else {
        formattedUrl = url;
      }
      window.location.href = formattedUrl;
    }

    if (action === 'existing_page' && existingPage) {
      navigate(getPagePathFromSlug(existingPage, sourceTable?.key));
    }

    if (action === 'child_page' && scene) {
      navigate(getChildPagePathFromSlug(scene));
    }

    if (action === 'parent_page') {
      navigate(getPreviousPagePath());
    }

    if (action === 'message') {
      setApplicableSubmitRuleValues((prev) => ({
        ...prev,
        confirmationMessage: message
      }));
    }
  };

  return {
    applicableSubmitRuleValues,
    setApplicableSubmitRuleValues,
    handleSubmitRules,
    checkCriteriaRules
  };
}
