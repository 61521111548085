import { queryOptions, useQuery } from '@tanstack/react-query';

import { type LiveAppPageFull } from '@/types/schema/LiveAppPage';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';

async function getPage(accountSlug: string, appSlug: string, pageSlug: string | undefined) {
  if (!pageSlug) {
    return undefined;
  }

  const { data: page } = await axios.get<LiveAppPageFull>(
    `/v1/live-app/${accountSlug}/${appSlug}/pages/${pageSlug}`,
    {
      withCredentials: true
    }
  );

  return page;
}

export function pageQuery({ pageSlug }: { pageSlug: string | undefined }) {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return queryOptions({
    queryKey: [queryKeys.page, pageSlug, accountSlug, appSlug],
    queryFn: async () => getPage(accountSlug, appSlug, pageSlug),
    throwOnError: true,
    enabled: !!pageSlug
  });
}

export function usePageQuery({ pageSlug }: { pageSlug: string | undefined }) {
  return useQuery(pageQuery({ pageSlug }));
}
