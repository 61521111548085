import { Fragment, type CSSProperties } from 'react';
import { Divider, RichTextRenderer, Table } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';

import { type DetailsView } from '@/types/schema/views/DetailsView';
import {
  type ListView,
  type ListViewColumn,
  type ListViewColumnType
} from '@/types/schema/views/ListView';
import { type MapView } from '@/types/schema/views/MapView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { ListActionButton } from '@/components/views/action-button/ListActionButton';
import { useDetailsDisplayRules } from '@/components/views/details/useDetailsDisplayRules';
import { Map } from '@/components/views/map/engine/Map';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';
import { getLabelStyles, getValueStyles } from './helper';
import { ListItemValueCell } from './ListItemCell';

const listActionLinksTypes: ListViewColumnType[] = ['scene_link', 'delete', 'action_link'];

export function ListItem({
  listRows,
  record,
  isSingleColumn
}: {
  listRows: ListViewColumn[];
  record: FormattedViewRecord;
  isSingleColumn?: boolean;
}) {
  const { theme } = useThemingContext();
  const { view, sourceTable } = useViewContext<ListView | DetailsView | MapView>();
  const shouldHideFields = view.type !== 'map' ? view.hide_fields : false;
  const rulesFields = view.type !== 'map' ? view.rules?.fields : undefined;
  const { processDisplayRuleActions } = useDetailsDisplayRules(record.values, rulesFields);

  // We reorganize the groups to be able to insert the dividers and special titles between the elements without breaking the UI
  function reorganizeGroups() {
    const newArray: { id: string; columns: ListViewColumn[] }[] = [];
    let temporalGroupRows: ListViewColumn[] = [];

    listRows.forEach((row) => {
      if (
        row.type === 'divider' ||
        row.type === 'special_title' ||
        listActionLinksTypes.includes(row.type)
      ) {
        // If there are rows in the current group, we add it to the newArray
        if (temporalGroupRows.length > 0) {
          newArray.push({ id: nanoid(5), columns: temporalGroupRows });
          temporalGroupRows = []; // Reset the current group
        }
        // Add the special row as a separate group
        newArray.push({ id: nanoid(5), columns: [row] });
      } else {
        // Add the normal row to the current group
        temporalGroupRows.push(row);
      }
    });

    // If there are rows in the current group after the loop, we add them
    if (temporalGroupRows.length > 0) {
      newArray.push({ id: nanoid(5), columns: temporalGroupRows });
    }

    return newArray;
  }

  const listRowsOrganizeGroups = reorganizeGroups();

  return (
    <div className="w-full space-y-2">
      {listRowsOrganizeGroups.map((group) => {
        if (group.columns[0].type === 'divider') {
          return <Divider key={`divider-${group.id}`} className="max-w-[400px] py-2" />;
        }

        if (listActionLinksTypes.includes(group.columns[0].type)) {
          return (
            <div // eslint-disable-next-line react/no-array-index-key
              className="mr-1 inline-block"
              key={`${group.columns[0].id}-${group.id}-${group.columns[0].id}`}
            >
              <ListActionButton column={group.columns[0]} record={record} />
            </div>
          );
        }

        if (group.columns[0].type === 'special_title') {
          return (
            <div key={`special-title-${group.id}`}>
              <p>{group.columns[0].value}</p>
              <RichTextRenderer dangerouslySetInnerHTML={{ __html: group.columns[0].copy }} />
            </div>
          );
        }

        return (
          <div
            key={`group-${group.id}`}
            className={cn('h-fit overflow-hidden border border-default bg-gray-900 text-sm', {
              'rounded-lg': theme.appearance.corners === 'rounded',
              'max-w-[400px]': isSingleColumn
            })}
          >
            <Table className="table overflow-auto">
              <Table.Body>
                {group.columns.map((section, sectionIndex) => {
                  const isCustomLabel =
                    section.format?.label_custom && section.format?.label_format !== 'none';
                  const defaultStyles = cn('border-default bg-card p-2', {
                    'bg-card hover:bg-card p-2': isCustomLabel
                  });

                  if (section.type === 'divider' || section.type === 'special_title') {
                    return null;
                  }

                  if (listActionLinksTypes.includes(section.type)) {
                    return null;
                  }

                  const processedDisplayRuleActions = processDisplayRuleActions(section.key);

                  if (processedDisplayRuleActions.shouldHideField) {
                    return null;
                  }

                  if (!record.values[section.key] && shouldHideFields) {
                    return null;
                  }

                  const field = sourceTable.fields.find((f) => f.key === section.key);

                  const tableCellDisplayRulesStyle: CSSProperties = {
                    backgroundColor: processedDisplayRuleActions.bgColor || 'none',
                    color: processedDisplayRuleActions.textColor || 'inherit'
                  };

                  const isLabelOnTop = section.format?.label_format === 'top';

                  if (isLabelOnTop) {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Fragment key={`row-top-${section.key}-${sectionIndex}`}>
                        <Table.Row key={`${section.key}-cell-top`}>
                          {section.format?.label_format !== 'none' && (
                            <Table.Cell
                              colSpan={2}
                              height={36}
                              className={cn(
                                'w-full border-r-0 border-default bg-muted p-2 font-medium hover:bg-muted',
                                getLabelStyles(section, processedDisplayRuleActions)
                              )}
                              style={tableCellDisplayRulesStyle}
                            >
                              {processedDisplayRuleActions.label || section.label || section.name}
                            </Table.Cell>
                          )}
                        </Table.Row>
                        <Table.Row key={`${section.key}-cell-bottom`} className={cn({})}>
                          <Table.Cell
                            colSpan={2}
                            height={36}
                            className={cn('bg-card p-2 hover:bg-card', getValueStyles(section))}
                            dangerouslySetInnerHTML={{ __html: record.values[section.key] }}
                            style={tableCellDisplayRulesStyle}
                          />
                        </Table.Row>
                      </Fragment>
                    );
                  }

                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Table.Row key={`row-${section.key}-${sectionIndex}`}>
                      {((view.type === 'map' &&
                        section.format?.label_custom &&
                        section.format?.label_format !== 'none') ||
                        (view.type !== 'map' && section.format?.label_format !== 'none')) && (
                        <Table.Cell
                          height={36}
                          className={cn(
                            'border-r-0 border-default bg-muted p-2 font-medium hover:bg-muted',
                            getLabelStyles(section, processedDisplayRuleActions)
                          )}
                          style={tableCellDisplayRulesStyle}
                        >
                          {processedDisplayRuleActions.label || section.label || section.name}
                        </Table.Cell>
                      )}
                      {view.type !== 'map' && section.show_map ? (
                        <Table.Cell
                          className={cn(defaultStyles, getValueStyles(section))}
                          colSpan={isCustomLabel ? 1 : 2}
                        >
                          <Map
                            formattedViewRecords={[record]}
                            mapHeight={section.map_height}
                            addressFieldKey={section.key}
                          />
                          <div
                            className="mt-2"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: record.values[section.key] }}
                          />
                        </Table.Cell>
                      ) : (
                        <ListItemValueCell
                          className={cn(
                            'ast-rich-text-renderer rich-text-renderer break-words',
                            defaultStyles,
                            getValueStyles(section, processedDisplayRuleActions),
                            'w-full max-w-[75%]'
                          )}
                          value={record.values[section.key]}
                          field={field}
                          style={tableCellDisplayRulesStyle}
                          height={36}
                          colSpan={isCustomLabel ? 1 : 2}
                          dangerouslySetInnerHTML={{ __html: record.values[section.key] }}
                        />
                      )}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        );
      })}
    </div>
  );
}
