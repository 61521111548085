import { useRef } from 'react';

import { useContainerQuery } from '@/hooks/useContainerQuery';
import {
  MapDetailsItemsContainer,
  type MapDetailsItemsContainerProps
} from '@/components/views/map/details/MapDetailsItemsContainer';
import {
  MapDetailsItemsContent,
  type MapDetailsItemsContentProps
} from '@/components/views/map/details/MapDetailsItemsContent';

export function MapDetailsItems({
  pagedViewRecords,
  columns,
  selectedRecord,
  detailsWidth,
  detailsHeight,
  onSelectRecord,
  isSmallVersion: isMapViewRenderSmall,
  isEmptyState,
  searchTerm,
  isLoading,
  noDataText,
  titleFieldKey
}: MapDetailsItemsContainerProps & MapDetailsItemsContentProps) {
  const containerRef = useRef(null);
  const isMapDetailsItemsSmall = useContainerQuery(containerRef, { maxWidth: 550 });

  return (
    <MapDetailsItemsContainer
      detailsWidth={detailsWidth}
      detailsHeight={detailsHeight}
      isSmallVersion={isMapViewRenderSmall}
    >
      <div ref={containerRef} className="flex size-full flex-col overflow-hidden">
        <MapDetailsItemsContent
          pagedViewRecords={pagedViewRecords}
          selectedRecord={selectedRecord}
          columns={columns}
          onSelectRecord={onSelectRecord}
          isEmptyState={isEmptyState}
          searchTerm={searchTerm}
          isLoading={isLoading}
          isSmallVersion={isMapDetailsItemsSmall}
          noDataText={noDataText}
          titleFieldKey={titleFieldKey}
        />
      </div>
    </MapDetailsItemsContainer>
  );
}
