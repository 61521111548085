import { useQuery } from '@tanstack/react-query';
import pick from 'lodash/pick';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { type ChartData, type ReportViewChart } from '@/types/schema/views/ReportView';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios, getAppBasedRequestHeaders } from '@/utils/axiosConfig';
import { isInternalBuilderIframe } from '@/utils/iframe';
import { usePageContext } from '@/context/PageContext';

async function getReport(pageKey: LiveAppPage['key'], report: ReportViewChart) {
  if (!isInternalBuilderIframe()) {
    return undefined;
  }

  const payload = {
    record_per_page: 25,
    page: 1,
    preview: true,
    report
  };

  // This is a POST route in the server, but we treat it as a GET route in the client.
  // The reason for using a POST is to avoid the URL length limit for report previewing so it can include large reports in the request body
  const { data } = await axios.post<{ reports: ChartData[] }>(
    `/v1/scenes/${pageKey}/views/new/report`,
    payload,
    {
      withCredentials: true,
      headers: getAppBasedRequestHeaders()
    }
  );

  return data.reports.length > 0 ? data.reports[0] : undefined;
}

export function useReportPreviewQuery({
  viewKey,
  report,
  enabled = true
}: {
  viewKey: LiveAppViewKey;
  report: ReportViewChart;
  enabled?: boolean;
}) {
  const { activePage } = usePageContext();

  if (!activePage) {
    throw new Error('Active page not found');
  }

  const reportSettingsQueryKey = JSON.stringify(
    pick(report, ['groups', 'filters', 'calculations', 'source'])
  );

  return useQuery({
    queryKey: [queryKeys.report, activePage.key, viewKey, report.id, reportSettingsQueryKey],
    queryFn: async () => getReport(activePage.key, report),
    enabled
  });
}
