import { useMemo } from 'react';
import { has } from 'lodash';

import { useAppInit } from '@/hooks/useAppInit';
import { isIframedByBuilder } from '@/utils/iframe';

const isDevelopment = process.env.NODE_ENV === 'knackcrm';

export function useSupportAccessTool() {
  const { application, account, isPending } = useAppInit();

  const isEnabled = useMemo(() => {
    // Trial / Starter / Pro / Corporate: always enabled
    if (account && account.productPlan.level < 4) {
      return true;
    }

    // Plus or higher, but doesn't have property set yet: always enabled
    if (application && !has(application.settings, 'hasSupportAccess')) {
      return true;
    }

    return application && application.settings.hasSupportAccess;
  }, [account, application]);

  const isVisible =
    window.location.hostname.includes(`knackcrm.com`) && isDevelopment && !isIframedByBuilder();
  const isUnavailable =
    (application && application.settings.cluster === 'us-govcloud') ||
    (account && account.settings.hipaa.enabled);

  return {
    isEnabled,
    isPending,
    isVisible,
    isUnavailable,
    isActive: !isPending && isEnabled && !isUnavailable && isVisible
  };
}
