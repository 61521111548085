import { forwardRef, useImperativeHandle } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { type z } from 'zod';

import { useAddRecordMutation } from '@/hooks/api/mutations/useAddRecordMutation';
import { useUpdateRecordMutation } from '@/hooks/api/mutations/useUpdateRecordMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { FormGroups } from '@/components/views/form/FormGroups';
import { getDefaultDatePayload } from '@/components/views/form/inputs/date-time/utils';
import { getDynamicFormSchema } from '@/components/views/form/schema/helper';
import {
  getDefaultFormValues,
  getFormViewInputs,
  getNormalizedFormValuesFromRecord
} from '@/components/views/form/utils';
import { canFieldStoreDateValues } from './helper';
import { type CalendarFormProps, type CalendarRef } from './type';

export const CalendarForm = forwardRef<CalendarRef, CalendarFormProps>(
  ({ view, sourceTable, recordValues, startDate, endDate, eventKey }, ref) => {
    const { data: application } = useApplicationQuery();
    const { mutate: addRecord } = useAddRecordMutation();
    const { mutate: updateRecord } = useUpdateRecordMutation({
      viewKey: view.key
    });

    const formSchema = getDynamicFormSchema({
      inputs: getFormViewInputs(view.form.groups),
      sourceTableFields: sourceTable.fields,
      ...(application?.settings.passwordOptions && {
        passwordOptions: application.settings.passwordOptions
      })
    });

    const formViewInputs = getFormViewInputs(view.form.groups);
    const defaultFormValues = getDefaultFormValues(formViewInputs, sourceTable.fields, {
      startDate,
      endDate,
      eventKey
    });

    type FormSchemaType = z.infer<typeof formSchema>;

    const form = useForm<FormSchemaType>({
      resolver: zodResolver(formSchema),
      defaultValues: recordValues
        ? getNormalizedFormValuesFromRecord(recordValues, formViewInputs, sourceTable.fields)
        : defaultFormValues
    });

    const handleSubmitForm = (data: FormSchemaType) =>
      new Promise((resolve, reject) => {
        let formData = data;
        const eventField = sourceTable.fields.find((field) => field.key === eventKey);
        if (
          eventKey &&
          !formData[eventKey] &&
          eventField &&
          startDate &&
          endDate &&
          canFieldStoreDateValues(eventField)
        ) {
          const eventFormat = eventField.format;

          const formattedDate = getDefaultDatePayload(eventFormat, {
            startDate,
            endDate,
            eventKey
          });
          formData = {
            ...data,
            [eventKey]: formattedDate
          };
        }

        if (recordValues) {
          updateRecord(
            {
              data,
              recordId: recordValues?.id
            },
            {
              onSuccess: (updatedRecord) => {
                resolve(updatedRecord);
              },
              onError: (error) => reject(error)
            }
          );
        } else {
          addRecord(
            {
              viewKey: view.key,
              data: formData
            },
            {
              onSuccess: (newRecord) => {
                resolve(newRecord);
                form.reset(defaultFormValues);
              },
              onError: (error) => {
                reject(error);
              }
            }
          );
        }
      });

    useImperativeHandle(ref, () => ({
      submitForm: async () =>
        new Promise((resolve) => {
          try {
            form
              .handleSubmit(handleSubmitForm)()
              .then(() => {
                const isError = !!Object.keys(form.formState.errors).length;
                resolve(!isError);
              })
              .catch(() => {
                resolve(false);
              });
          } catch (err) {
            resolve(false);
          }
        }),
      cancelForm: () => {
        form.reset();
      }
    }));

    return (
      <FormProvider {...form}>
        <form className="space-y-2 px-1" data-testid="form-view">
          <FormGroups formGroups={view.form.groups} />
        </form>
      </FormProvider>
    );
  }
);

CalendarForm.displayName = 'CalendarForm';
