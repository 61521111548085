import { type ChartConfig } from '@knack/asterisk-react';

type CustomProps = {
  paddingAngle?: number;
  endAngle?: number;
  startAngle?: number;
  maxRadius?: number;
  outerRadius?: number;
  innerRadius?: number;
  tooltipPosition?: any;
  middleRadius?: number;
  midAngle?: number;
  tooltipPayload?: any;
  cornerRadius?: number;
};

export function CustomLabel({
  chartConfig,
  payload,
  ...props
}: {
  chartConfig: ChartConfig;
  payload: {
    calc_format: string;
    raw_0: string;
  };
} & CustomProps) {
  // Skip passing these props to the <text> element
  const {
    paddingAngle,
    endAngle,
    startAngle,
    maxRadius,
    outerRadius,
    innerRadius,
    tooltipPosition,
    middleRadius,
    midAngle,
    tooltipPayload,
    cornerRadius,
    ...rest
  } = props;
  return <text {...rest}>{payload.calc_format || payload.raw_0}</text>;
}
