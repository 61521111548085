import { type ListViewColumn } from '@/types/schema/views/ListView';
import { type FormattedDetailsDisplayRuleAction } from '@/components/views/details/useDetailsDisplayRules';

export function getLabelStyles(
  currentSection: ListViewColumn,
  displayRules?: FormattedDetailsDisplayRuleAction
) {
  return {
    'text-right': currentSection.format?.label_format === 'right',
    'text-left': currentSection.format?.label_format === 'left',
    italic: displayRules?.italic,
    'line-through': displayRules?.strikethrough
  };
}

export function getValueStyles(
  currentSection: ListViewColumn,
  displayRules?: FormattedDetailsDisplayRuleAction
) {
  return {
    'text-4xl': currentSection.format?.styles?.includes('h1'),
    'text-2xl': currentSection.format?.styles?.includes('h2'),
    'font-bold': currentSection.format?.styles?.includes('strong') || displayRules?.bold,
    'font-normal italic': currentSection.format?.styles?.includes('em'),
    italic: displayRules?.italic,
    'line-through': displayRules?.strikethrough
  };
}
