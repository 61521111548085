import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronDownIcon, HiChevronUp as ChevronUpIcon } from 'react-icons/hi2';
import { Collapsible, Divider, Table } from '@knack/asterisk-react';

import { type ChartRecord, type ReportViewChart } from '@/types/schema/views/ReportView';
import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';

interface ChartDataTableProps {
  chart: ReportViewChart;
  chartRecords: ChartRecord[];
}

export function ChartDataTable({ chart, chartRecords }: ChartDataTableProps) {
  const { theme } = useThemingContext();
  const [t] = useTranslation();
  const [isTableVisible, setTableVisible] = useState(false);

  const handleToggle = () => {
    setTableVisible((prevState) => !prevState);
  };

  return (
    <>
      <Divider className="my-4" />
      <Collapsible defaultOpen={isTableVisible}>
        <Collapsible.Trigger
          className="group flex items-center gap-2 text-subtle"
          onClick={handleToggle}
        >
          <ChevronDownIcon size={16} className="ml-auto group-data-[state=open]:hidden" />
          <ChevronUpIcon size={16} className="ml-auto group-data-[state=closed]:hidden" />
          {t('components.views.report.data_table')}
        </Collapsible.Trigger>

        <Collapsible.Content className="mt-1 flex flex-col gap-1">
          <div
            className={cn('my-6 h-fit overflow-hidden border border-default', {
              'rounded-lg': theme.appearance.corners === 'rounded'
            })}
          >
            <Table className="table overflow-auto">
              <Table.Header>
                <Table.Row>
                  <Table.Head>{chart.groups[0].label}</Table.Head>
                  {chart.calculations.map((calculation) => (
                    <Table.Head key={calculation.calculation}>{calculation.label}</Table.Head>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {chartRecords.map((record) => (
                  <Table.Row key={record.key}>
                    <Table.Cell key={record.group_0}>{record.group_0}</Table.Cell>
                    {chart.calculations.map((calculation, index) => (
                      <Table.Cell key={`${calculation.calculation}-${record.key}`}>
                        {record[`calc_${index}`]}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Collapsible.Content>
      </Collapsible>
    </>
  );
}
