import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label } from '@knack/asterisk-react';

import { type FormViewLinkInput } from '@/types/schema/views/form/Link';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';

export function LinkInput({ input }: { input: FormViewLinkInput }) {
  const [t] = useTranslation();
  const { view } = useViewContext();
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const inputFormat = input.format;
  const isReadOnly = input.read_only;

  const fieldError = errors[input.field.key];
  const fieldErrorKeyUrl = fieldError?.type ? input.field.key : `${input.field.key}.url`;

  if (inputFormat.text_format === 'unique') {
    return (
      <>
        <div className="mb-2">
          <Input
            id={`${view.key}-${input.id}-label`}
            disabled={isReadOnly}
            {...register(`${input.field.key}.label`)}
          />
          <FormErrorMessage className="mt-1" errors={errors} name={`${input.field.key}.label`} />
          <Label className="mt-1 block text-subtle" htmlFor={`${view.key}-${input.id}-label`}>
            {t('components.views.form.link_input.link_text')}
          </Label>
        </div>

        <div>
          <Input
            id={`${view.key}-${input.id}-url`}
            data-testid="form-view-link-input"
            intent={fieldError ? 'destructive' : undefined}
            placeholder={t('components.views.form.link_input.placeholder')}
            disabled={isReadOnly}
            {...register(`${input.field.key}.url`)}
          />
          <FormErrorMessage className="mt-1" errors={errors} name={fieldErrorKeyUrl} />
          <Label className="mt-1 block text-subtle" htmlFor={`${view.key}-${input.id}-url`}>
            {t('components.views.form.link_input.link_url')}
          </Label>
        </div>
      </>
    );
  }

  return (
    <>
      <Input
        id={`${view.key}-${input.id}`}
        data-testid="form-view-link-input"
        intent={fieldError ? 'destructive' : undefined}
        placeholder={t('components.views.form.link_input.placeholder')}
        disabled={isReadOnly}
        {...register(`${input.field.key}.url`)}
      />
      <FormErrorMessage errors={errors} name={fieldErrorKeyUrl} />
    </>
  );
}
