import { useLocation } from 'react-router-dom';

import { type LiveAppTableKey } from '@/types/schema/LiveAppTable';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { usePageContext } from '@/context/PageContext';

export function usePageSegmentsHelpers() {
  const location = useLocation();

  const { activePage, pageSegments } = usePageContext();
  const { data: application } = useApplicationQuery();

  const getActivePageSegment = () => {
    if (pageSegments.length === 0) {
      return null;
    }

    return pageSegments[pageSegments.length - 1];
  };

  const getActivePagePathname = () => {
    if (!activePage) {
      return '/';
    }

    // If the current location has no page segments, it means it's the home page.
    // However, we still need the page's slug so we can generate the correct path when linking to child pages.
    if (location.pathname === '/') {
      return `/${activePage.slug}`;
    }

    return location.pathname;
  };

  const getPreviousPagePath = () => {
    // If there is only one or fewer page segments, there is no previous page
    if (pageSegments.length <= 1) {
      return '/';
    }

    let pathname = '';

    pageSegments.forEach((segment, index) => {
      // We don't want to include the last segment in the pathname
      if (index === pageSegments.length - 1) {
        return;
      }

      pathname += `/${segment.slug}`;

      if (segment.recordId) {
        pathname += `/${segment.recordId}`;
      }
    });

    return pathname;
  };

  const getChildPagePathFromSlug = (slug: string) => {
    const activeSegment = getActivePageSegment();
    let childPagePath = `${getActivePagePathname()}/${slug}`;

    if (activeSegment?.recordId) {
      childPagePath += `/${activeSegment.recordId}`;
    }

    return childPagePath;
  };

  const getPagePathFromSlug = (slug: string, viewSourceObjectKey: LiveAppTableKey | undefined) => {
    const pages = application?.pages || [];
    const activeSegment = getActivePageSegment();

    let pagePath = `/${slug}`;

    const page = pages.find((p) => p.slug === slug);

    const isSameSourceObject =
      page?.sourceObjectKey && page?.sourceObjectKey === viewSourceObjectKey;

    // If the source object of the page to redirect to is the same as the source object of the view, we need to include the record ID in the path (if there is one)
    if (activeSegment?.recordId && isSameSourceObject) {
      pagePath += `/${activeSegment.recordId}`;
    }

    let parentPage = pages.find((p) => p.slug === page?.parentSlug);

    while (parentPage) {
      if (!parentPage || parentPage.type === 'authentication') {
        break;
      }

      pagePath = `/${parentPage.slug}${pagePath}`;
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      parentPage = pages.find((p) => p.slug === parentPage?.parentSlug);
    }

    return pagePath;
  };

  return {
    getActivePageSegment,
    getActivePagePathname,
    getPagePathFromSlug,
    getChildPagePathFromSlug,
    getPreviousPagePath
  };
}
