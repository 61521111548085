import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { usePageContext } from '@/context/PageContext';

interface AddOptionParams {
  pageKey: string;
  viewKey: string;
  data: any;
}

export async function addRecord({ pageKey, viewKey, data }: AddOptionParams) {
  const url = `/v1/scenes/${pageKey}/views/${viewKey}/records/`;

  const { data: responseData } = await axios.post<{ record: ViewRecord }>(
    url,
    {
      ...data
    },
    {
      withCredentials: true
    }
  );

  return responseData.record;
}

export function useAddRecordMutation() {
  const queryClient = useQueryClient();
  const { activePage } = usePageContext();

  if (!activePage) {
    throw new Error('Active page not found');
  }

  return useMutation({
    mutationFn: ({ viewKey, data }: { viewKey: string; data: any }) =>
      addRecord({ pageKey: activePage.key, viewKey, data }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.viewData]
      });
    }
  });
}
