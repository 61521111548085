import { useEffect, useState } from 'react';
import { HiFunnel as FilterIcon } from 'react-icons/hi2';
import { Tabs } from '@knack/asterisk-react';

import { type KnackMenuFilter } from '@/types/schema/LiveAppView';
import { useViewFilters } from '@/hooks/useViewFilters';
import { useViewSearchParams, type ViewWithSearchParams } from '@/hooks/useViewSearchParams';
import { isPageEditor } from '@/utils/iframe';
import { useViewContext } from '@/components/views/ViewContext';

export function ViewMenuFilters({ menuFilters }: { menuFilters: KnackMenuFilter[] }) {
  const { view, sourceTable } = useViewContext<ViewWithSearchParams>();
  const { activeViewFilters } = useViewSearchParams(view);
  const { saveActiveFilters } = useViewFilters({ view, sourceTable });

  const [tabComponentKey, setTabComponentKey] = useState(0);
  const [activeMenuFilter, setActiveMenuFilter] = useState<KnackMenuFilter | null>();

  const handleSaveMenuFilters = (menuFilter: KnackMenuFilter) => {
    const selectedMenuFilter = menuFilters.find((filter) => filter.key === menuFilter.key);

    if (!selectedMenuFilter) return;

    setActiveMenuFilter(menuFilter);
    saveActiveFilters({
      match: 'and',
      rules: [
        {
          match: 'and',
          field: selectedMenuFilter.field,
          operator: selectedMenuFilter.operator,
          value: selectedMenuFilter.value,
          field_name: selectedMenuFilter.text
        }
      ]
    });
  };

  const handleSelectMenuFilter = (selectedMenuFilter: KnackMenuFilter) => {
    // Disable the filter selection on the page editor
    if (isPageEditor()) return;

    const shouldUnselectFilter =
      activeMenuFilter && selectedMenuFilter.key === activeMenuFilter.key;

    if (shouldUnselectFilter) {
      setActiveMenuFilter(null);
      saveActiveFilters(null);
      setTabComponentKey(tabComponentKey + 1);
      return;
    }

    handleSaveMenuFilters(selectedMenuFilter);
  };

  useEffect(() => {
    if (menuFilters.length > 0) {
      // If we have menu filters, we select the first one by default
      handleSaveMenuFilters(menuFilters[0]);
    }

    if (!activeViewFilters?.rules.length) return;

    const matchingFilter = menuFilters.find((menuFilter) =>
      activeViewFilters.rules.some(
        (rule) =>
          rule.field === menuFilter.field &&
          rule.value === menuFilter.value &&
          rule.operator === menuFilter.operator
      )
    );

    if (matchingFilter) {
      setActiveMenuFilter(matchingFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuFiltersItems = menuFilters.map((filter) => ({
    value: filter.key,
    children: <span>{filter.text}</span>,
    onClick: () => handleSelectMenuFilter(filter)
  }));

  // We need to re-render the tabs component when the menu filters are updated in the page editor
  useEffect(() => {
    if (isPageEditor()) {
      setTabComponentKey(tabComponentKey + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuFilters]);

  return (
    <Tabs key={tabComponentKey} value={activeMenuFilter?.key}>
      <Tabs.List items={menuFiltersItems} intent="default">
        <FilterIcon size={16} className="mr-1" />
      </Tabs.List>
    </Tabs>
  );
}
