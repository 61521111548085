import { DateTime } from 'luxon';

import { type DateTimeField } from '@/types/schema/fields';
import { type CalendarFormDateDefaultValues } from '@/components/views/calendar/type';
import { defaultDateFormatMap } from './types';

export type DateConvertedFormat = 'none' | 'dd/MM/yyyy' | 'MM/dd/yyyy';

export const checkIsValidDate = (value: string, dateFormat: DateConvertedFormat) =>
  DateTime.fromFormat(value, dateFormat).isValid ||
  DateTime.fromFormat(value, dateFormat.replace('yyyy', 'yy')).isValid;

export function isManualDateInputValid(inputValue: string) {
  // This regex does the following:
  // - Allows only numbers and slashes
  // - Disallows a double slash
  const regex = /^(?!.*\/\/)[0-9/]*\/?$/;
  return regex.test(inputValue);
}

export const getFormattedDatePickerDate = (date: string, dateFormat: DateConvertedFormat) =>
  !date || !checkIsValidDate(date, dateFormat)
    ? DateTime.now().toJSDate()
    : DateTime.fromFormat(date, dateFormat.replace('yyyy', 'yy')).toJSDate();

export function formatTimeToString({
  inputFormat,
  additionalHours,
  defaultDate
}: {
  inputFormat: DateTimeField['format'];
  additionalHours?: number;
  defaultDate?: Date;
}) {
  const defaultTime = inputFormat.default_time;
  const defaultFormat = inputFormat.time_format === 'HH:MM am' ? 'hh:mm a' : 'HH:mm a';

  if (inputFormat.time_type === 'current') {
    if (defaultDate) {
      return DateTime.fromJSDate(defaultDate).toFormat(defaultFormat);
    }
    if (additionalHours) {
      return DateTime.now().plus({ hours: additionalHours }).toFormat(defaultFormat);
    }
    return DateTime.now().toFormat(defaultFormat);
  }

  if (inputFormat.time_type === 'time' && defaultTime) {
    const timeFormat = defaultFormat.slice(0, -2);
    if (additionalHours) {
      return DateTime.fromFormat(defaultTime, timeFormat)
        .plus({ hours: additionalHours })
        .toFormat(timeFormat);
    }
    return DateTime.fromFormat(defaultTime, timeFormat).toFormat(defaultFormat);
  }

  return '';
}

export const getRawTime = (timeWithMeridiem: string) => timeWithMeridiem.slice(0, -3);

export const getDefaultDatePayload = (
  inputFormat: DateTimeField['format'],
  defaultValues?: CalendarFormDateDefaultValues
) => {
  const dateFormat = defaultDateFormatMap[inputFormat.date_format];
  const isRangeMode = inputFormat.calendar || false;

  const todayFormatted = DateTime.now().toFormat(dateFormat);
  const defaultDate =
    inputFormat.default_type === 'date' && inputFormat.default_date
      ? inputFormat.default_date
      : todayFormatted;

  if (inputFormat.time_format === 'Ignore Time') {
    return {
      date: defaultDate || todayFormatted,
      ...(isRangeMode && {
        to: {
          date: defaultDate || todayFormatted
        }
      })
    };
  }

  const startTime = formatTimeToString({
    inputFormat,
    defaultDate: defaultValues?.startDate
  });

  const endTime = formatTimeToString({
    inputFormat,
    additionalHours: 1,
    defaultDate: defaultValues?.endDate
  });

  const defaultFormattedStartDate =
    defaultValues?.startDate && DateTime.fromJSDate(defaultValues?.startDate).toFormat(dateFormat);
  const defaultFormattedEndDate =
    defaultValues?.endDate && DateTime.fromJSDate(defaultValues?.endDate).toFormat(dateFormat);

  return {
    ...(inputFormat.date_format !== 'Ignore Date' && {
      date: defaultFormattedStartDate || defaultDate
    }),
    am_pm: startTime.slice(-2),
    hours: startTime.slice(0, 2),
    minutes: startTime.slice(3, 5),
    rawTime: getRawTime(startTime),
    ...(isRangeMode && {
      to: {
        date: defaultFormattedEndDate || defaultDate,
        am_pm: endTime.slice(-2),
        hours: endTime.slice(0, 2),
        minutes: endTime.slice(3, 5),
        rawTime: getRawTime(endTime)
      }
    })
  };
};

export const getRepeatDefaultOptions = () => ({
  frequency: 'daily',
  interval: '1',
  SU: false,
  MO: false,
  TU: false,
  WE: false,
  TH: false,
  FR: false,
  SA: false,
  repeatby: 'dom',
  endson: 'never',
  end_count: '',
  end_date: ''
});

export const getTimeValue = (
  valueTime: string
): Record<'hours' | 'minutes' | 'rawTime', string> => {
  const [hours, minutes] = valueTime.split(':');
  return {
    hours,
    minutes,
    rawTime: valueTime
  };
};
