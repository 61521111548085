import { type FormView } from '@/types/schema/views/FormView';
import { FormFieldInput } from './FormFieldInput';
import { FormStaticInput } from './FormStaticInput';

interface FormGroupsProps {
  formGroups: FormView['groups'];
}

export function FormGroups({ formGroups }: FormGroupsProps) {
  return (
    <div className="flex flex-col gap-12">
      {formGroups.map((group, gIndex) => (
        <div
          key={group.id || `g-${gIndex}`}
          className="block flex-wrap gap-4 sm:flex sm:flex-nowrap"
        >
          {group.columns.map((column, cIndex) => (
            <div key={column.id || `c-${cIndex}`} className="flex flex-1 flex-col">
              {column.inputs.map((input, index) => {
                const isStaticField = input.type === 'section_break' || input.type === 'divider';

                if (isStaticField) {
                  return <FormStaticInput key={input.id || `i-${index}`} input={input} />;
                }

                return <FormFieldInput key={input.id || `i-${index}`} input={input} />;
              })}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
